<template>
	<div class="app-nav__create-holder">
		<a
			class="app-nav__create"
			@click.prevent="createNewPost"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="w-6 h-6"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 4.5v15m7.5-7.5h-15"
				/>
			</svg>
		</a>
	</div>
</template>

<script>
export default {
	methods: {
		createNewPost () {
			this.$root.$emit('postUpdate');
		},
	},
};
</script>
</template>
