// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import ErrorComponent from '@/components/ErrorComponent';
import 'reset-css';
import '@/scss/app.scss';
import AuthService from '@/services/AuthService';
import Header from '@/components/HeaderComponent';
import Nav from '@/components/NavComponent';
import QuickLinks from '@/components/QuickLinksComponent';
import moment from 'moment';
import store from '@/store/index.js';
import router from './router';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
import Vuelidate from 'vuelidate';
import vueDebounce from 'vue-debounce';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import jQuery from 'jquery';
import './registerServiceWorker';
import { ToggleButton } from 'vue-js-toggle-button'
import VTooltip from 'v-tooltip'


// Import and use Vue Froala lib.
import Editor from '@tinymce/tinymce-vue'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Settings.defaultLocale = 'en-GB';

Vue.use(Vuelidate);
Vue.use(vueDebounce);
Vue.use(Editor)

Vue.config.productionTip = false
// Setup and config momentjs
window.moment = moment;
window.moment.locale('en-gb');

window.jQuery = jQuery;
window.$ = jQuery;

window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;


// Setup and initialise the AuthService
window.auth = AuthService;
window.auth.init();

// Setup axios interceptors
window.axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		if (error.response.status === 403) {
			window.auth.logout();
		}
		if (error.response.status === 401) {
			window.auth.logout();
		}
	}
	return Promise.reject(error);
});

Vue.config.productionTip = false;

Vue.use(Datetime);

if (process.env.BUGSNAG_KEY) {
	window.bugsnagClient = bugsnag(process.env.VUE_APP_BUGSNAG_KEY);
	window.bugsnagClient.use(bugsnagVue, Vue);
}

// Register some global components
Vue.component('app-error', ErrorComponent);
Vue.component('app-header', Header);
Vue.component('app-nav', Nav);
Vue.component('app-quick-links', QuickLinks);
Vue.component('v-select', vSelect)
Vue.component('ToggleButton', ToggleButton)

Vue.use(VTooltip)


/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	components: { App },
	template: '<App/>',
	store,
});

// @Todo confirm not a btter way of doing this
router.afterEach(( to ) => {
	gtag('event', 'page_view', { // eslint-disable-line
		page_title: to.name,
		page_location: to.fullPath,
		page_path: to.path,
	})
});