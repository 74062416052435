<template>
	<div class="container training-sessions">
		<h2 class="p-4 section__title">Training Sessions</h2>
			<div v-if="loading" class="loading-spinner">
				<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
					<g fill="none" fill-rule="evenodd">
						<g transform="translate(1 1)" stroke-width="2">
							<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
							<path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 18 18"
									to="360 18 18"
									dur="1s"
									repeatCount="indefinite"/>
							</path>
						</g>
					</g>
				</svg>
			</div>
			<p class="training-sessions__intro" v-if="sessions.length === 0 && !loading">Looks like we couldn't find any training sessions, create a new one by clicking the button below</p>
			<p class="training-sessions__intro" v-if="filteredList.length === 0 && !loading && search">We were unable to find any results for that search, please try another</p>
			<div v-if="!loading">
				<input type="search" name="search" v-model="search" placeholder="Search Sessions by Name" class="form__control form__control--bordered session-search"/>
				<draggable :list="filteredList" @change="moved">
						<div class="flex training-sessions__item" v-for="(session, index) in filteredList" :key="session.id" :class="{'training-sessions__item--new' : session.id === qParams.new}">
							<div>
								<h3 class="training-sessions__item__title">{{session.title}}
									<span v-if="session.id === qParams.new" class="training-sessions__label training-sessions__label--new">New</span>
									<span v-if="session.id === qParams.saved" class="training-sessions__label training-sessions__label--updated">Updated</span>
								</h3>
								<p class="training-sessions__item__meta">{{session.questions.length}} Questions | {{session.attendees.length}} Attendees | {{ dateFormat(session.session_date) }} </p>
								<span class="" style="font-size:10px; display: block; margin-top: 5px; font-style: italic">Created by {{session.created_by}}</span>
							</div>
							<a :href="'/training-sessions/'+session.id" class="training-sessions__item__edit">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11 5.00001H6C5.46957 5.00001 4.96086 5.21072 4.58579 5.5858C4.21071 5.96087 4 6.46958 4 7.00001V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V13L11 5.00001ZM17.586 3.58601C17.7705 3.39499 17.9912 3.24262 18.2352 3.13781C18.4792 3.03299 18.7416 2.97782 19.0072 2.97551C19.2728 2.9732 19.5361 3.0238 19.7819 3.12437C20.0277 3.22493 20.251 3.37343 20.4388 3.56122C20.6266 3.74901 20.7751 3.97231 20.8756 4.2181C20.9762 4.46389 21.0268 4.72725 21.0245 4.99281C21.0222 5.25837 20.967 5.52081 20.8622 5.76482C20.7574 6.00883 20.605 6.22952 20.414 6.41401L11.828 15H9V12.172L17.586 3.58601Z" stroke="#02B3E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</a>
							<button class="icon-button" style="color: #F96F6F;" @click="checkDeleteSession(session.id, index)">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>
							</button>
						</div>
				</draggable>
			</div>
			<div class="delete-modal" v-if="deleting">
				<p>Please confirm you wish to remove this training session. Only an admin will be able to recover it for you.</p>
				<div class="delete-modal__actions">
				<button class="button button--pill button--grey bold ripple" style="margin-right: 10px;" @click="deleting = false">Cancel</button>
				<button class="button button--pill button--red bold" @click="confirmDeleteSession">Confirm Delete</button>
				</div>
			</div>
		<a href="/training-sessions/new" class="button button--pill new-training-session" style="text-align:center;">+</a>
	</div>
</template>

<script>
import TrainingSessionsService from '@/services/TrainingSessionsService';
import draggable from 'vuedraggable'

export default {
	components: {
		draggable
	},

	props: {

	},

	data() {
		return {
			search: '',
			sessions: [],
			loading: true,
			deleting: false,
			deleteSession: null,
			qParams: {
				new: 0,
				saved: 0,
			},
			updatedId: 0,
		}
	},

	mounted() {
		this.loadTrainingSessions();
		if(this.$route.query); {
			this.qParams = this.$route.query;
		}
	},

	computed: {
		filteredList: {
			get(){
				if(!this.search) {
					return this.sessions;
				}
				return this.sessions.filter(post => {
					if(post.title.toLowerCase().includes(this.search.toLowerCase())) {
						return post;
					}

					if(post.created_by.toLowerCase().includes(this.search.toLowerCase())) {
						return post;
					}
				})
			},
		}
	},
	
	methods: {
		loadTrainingSessions() {
			this.loading = true;
			let localUser = window.localStorage.getItem('user');
			if(localUser) {
				localUser = JSON.parse(localUser);
				TrainingSessionsService.index(localUser.id).then(res => {
					this.loading = false;
					this.sessions = res.data;
				});
			}
		},

		moved() {
			let mapped = this.filteredList.map(function(element, index) {
				return {id: element.id, index: index}
			});

			TrainingSessionsService.updateOrder(mapped)
		},

		dateFormat(datetimestamp) {
			return window.moment(datetimestamp).format('DD/MM/Y')
		},

		checkDeleteSession(id,i) {
			this.deleteSession = {id: id, index: i};
			this.deleting = true;
		},

		confirmDeleteSession() {
			this.loading = true;
			TrainingSessionsService.delete(this.deleteSession.id).then(() => {
				this.loading = false;
				this.deleting = false;
				this.sessions.splice(this.deleteSession.index,1);
			});
		}
	}
};
</script>
<style scoped>
.loading-spinner {
	margin: 20px 0;
	text-align: center;
}

.new-training-session {
	position: fixed;
	bottom: 7.5rem;
	padding: 0;
	right: 1rem;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-decoration: none;
	font-size: 32px;
}

.session-search {
	margin: 1rem 0;
}
</style>