<template>
	<Transition>
		<div
			v-show="show"
			class="flash-message"
			role="alert"
		>
			<span class="font-medium">
				{{ message }}
			</span>
		</div>
	</Transition>
</template>

<script>
export default {
	components: {
	},

	props: {
		message: {
			required: true
		},
		show: {
			default: false
		}
	},

	data () {
		return {
		}
	},
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>