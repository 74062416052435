<template>
	<div>
		<div v-if="formSubmitted">
			<header class="feedback-form__header">
				<h3 class="feedback-form__title">Thank you for your feedback.</h3>
				<p class="feedback-form__description">The management team regularly review your feedback and this is very important to us. If you wish to discuss anything further then please don't hesitate to speak with your manager.</p>
			</header>
		</div>

		<form
			v-else
			action="#"
			class="feedback-form"
			@submit.prevent="submit()"
		>
			<header class="feedback-form__header">
				<h3 class="feedback-form__title">{{ form.title }}</h3>
				<p class="feedback-form__description">{{ form.description }}</p>
			</header>

			<div
				v-for="(field, field_index) in form.fields"
				:key="field_index"
			>
				<div v-if="field.visibility !== 'hidden' && conditionalLogicCheck(field)" :class="field.cssClass">
					<div v-if="field.type === 'text' || field.type === 'email' || field.type === 'password'">
						<fieldset>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<input
								:name="field.id"
								:id="field.id"
								:type="field.type"
								:required="field.isRequired"
								v-model="fields[field.id]"
								class="feedback-form__text"
							>
						</fieldset>
					</div>

					<div v-if="field.type === 'select' || field.type === 'multiselect'">
						<fieldset>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<select
								:name="field.id"
								:id="field.id"
								:required="field.isRequired"
								:multiple="field.type === 'multiselect'"
								class="feedback-form__select"
							>
								<option
									v-for="(choice, index) in field.choices"
									:key="index"
									:value="choice.value"
								>
									{{ choice.value }}
								</option>
							</select>
						</fieldset>
					</div>

					<div v-if="field.type === 'checkbox'">
						<div>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<div
								v-for="(input, index) in field.inputs"
								:key="index"
								class="feedback-form__checkbox-wrap"
							>
								<label
									:for="get_input_id(field, index)"
									class="feedback-form__checkbox-label"
									v-text="input.label"
									v-if="field.labelPlacement !== 'hidden_label'"
								/>

								<input
									:id="get_input_id(field, index)"
									:name="field.id + '[]'"
									v-model="fields[input.id]"
									:value="field.choices[index].value"
									:true-value="field.choices[index].value"
									class="feedback-form__checkbox"
									type="checkbox"
								>
							</div>
						</div>
					</div>

					<div
						v-if="field.type === 'radio'"
						:class="field.cssClass">
						<fieldset>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<div class="feedback-form__options">
								<div
									v-for="(choice, ci) in field.choices"
									:key="ci"
									class="feedback-form__checkbox-wrap"
								>
									<label
										v-if="field.cssClass !== 'wellbeing-rag'"
										:for="get_input_id(field, ci)"
										class="feedback-form__checkbox-label"
										v-text="choice.value"
									/>
									<input
										:id="get_input_id(field, ci)"
										:name="field.id"
										v-model="fields[field.id]"
										:value="choice.value"
										:true-value="choice.value"
										:required="field.isRequired && (!fields[field.id] || !fields[field.id].length)"
										class="feedback-form__checkbox feedback-form__checkbox--radio"
										type="radio"
									>

									<label
										v-if="field.cssClass === 'wellbeing-rag'"
										:for="get_input_id(field, ci)"
										class="feedback-form__checkbox-label"
									>
										<img
											v-if="choice.value === 'Green'"
											src="../../svg/happy.svg">
										<img
											v-if="choice.value === 'Amber'"
											src="../../svg/ok.svg">
										<img
											v-if="choice.value === 'Red'"
											src="../../svg/sad.svg">
									</label>
								</div>
							</div>
						</fieldset>
					</div>

					<div
						v-if="field.type === 'textarea'"
						class="feedback-form__textarea-wrap"
					>
						<label
							:for="field.id"
							class="feedback-form__textarea-label"
						>
							{{ field.label }}
							<span
								v-if="field.isRequired"
								class="required"
							>*</span>
						</label>
						<textarea
							:name="field.id"
							:id="field.id"
							v-model="fields[field.id]"
							:required="field.isRequired"
							class="feedback-form__textarea"
							cols="30"
							rows="5"
						/>
					</div>
					<div
						v-if="field.type === 'hidden'"
						class="feedback-form__hidden-wrap hidden-field"
					>
						<input
							:name="field.id"
							:id="field.id"
							v-model="fields[field.id]"
							type="hidden"
						>
					</div>
				</div>
			</div>

			<div>
				<button
					type="submit"
					class="feedback-form__submit"
					:disabled="submitting">
					{{ form.button.text }}
					<svg width="24" height="24" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#000" v-if="submitting" style="margin-bottom: -5px"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /> <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /> </circle> <circle cx="22" cy="22" r="1"> <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /> <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /> </circle> </g> </svg>
				</button>
			</div>

			<error-component
				v-if="errorMessage"
				:text="errorMessage"
			/>
		</form>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ErrorComponent from '@/components/ErrorComponent';
import CookieService from '@/services/CookiesService';
import GravityFormService from '@/services/GravityFormService';
import TrainingSessionsService from '@/services/TrainingSessionsService';
import {mapState} from 'vuex';

export default {

	components: {
		ErrorComponent,
	},

	props: {
		form: {
			type: Object,
			required: true,
		},
	},

	data: function () {
		return {
			fields: {},
			errorMessage: '',
			formSubmitted: false,
			submitting: false,
			trainingSession: {},
		};
	},

	computed: {
		...mapState(['user']),
	},

	validations () {
		let fields = {};

		this.form.fields.forEach(function (field) {
			if (field.isRequired === true) {
				fields[field.id] = {
					required,
				};
			}
		});

		return {
			fields,
		};
	},

	watch: {
		form () {
			this.initFields();
		},
	},

	mounted () {
		if (this.$route.query.result) {
			this.setRAGField();
		}

		if (CookieService.get('formsubmitted_' + this.form.id)) {
			this.formSubmitted = true;
		}

		// If Trainer Questionnaire, load session
		if(this.$route.query.training) {
			this.loadTrainingSession(this.$route.query.training)
		} else {
			this.initFields();
		}

	},

	methods: {
		setRAGField () {
			let feeling = this.form.fields.find(function (elem) {
				return elem.adminLabel === 'Feeling';
			});

			if (typeof (feeling) === 'undefined') {
				return;
			}

			this.fields[feeling.id] = this.$route.query.result;
		},

		get_input_id (field, input = null) {
			let id = 'field-' + field.id;
			if (input !== null) {
				id += '-' + input;
			}

			return id;
		},

		loadTrainingSession(trainingSession) {
			TrainingSessionsService.show(trainingSession).then(res => {
				this.trainingSession = res.data;
				this.initFields();
			});
		},

		conditionalLogicCheck(field) {
			if(typeof field.conditionalLogic === 'object') {
				let show = false;
				field.conditionalLogic.rules.forEach(rule => {
					if(rule.operator === 'is') {
						if(this.fields[rule.fieldId] === rule.value) {
							show = true;
						}
					}
				});

				return show;
			}

			return true;
		},

		initFields () {
			const fields = {};
			this.form.fields.forEach(field => {
				fields[field.id] = '';

				if (field.type === 'checkbox') {
					fields[field.id] = [];
				}

				if (field.type === 'consent') {
					fields[field.id] = '';
				}

				if (field.label.toLowerCase().includes('team id')) {
					fields[field.id] = this.user.team_id;
				}

				if (field.label.toLowerCase() === 'name' || field.inputName === 'user_name' ) {
					fields[field.id] = this.user.name;
				}

				if (typeof this.$route.query.feedback_result !== 'undefined') {
					if (field.label.toLowerCase() === 'how are you feeling?') {
						fields[field.id] = this.$route.query.feedback_result;
					}
				}

				if (field.adminLabel === 'Areas Covered' && this.trainingSession && this.trainingSession.areas_covered) {
					field.disabled = true;
					field.choices.forEach((choice, idx) => {
						let prePopped = this.trainingSession.areas_covered.find((area) => {
							return area === choice.value
						});

						if(prePopped) {
							fields[field.id+'.'+(idx+1)] = choice.value;
						}
					});
				}

				if(field.allowsPrepopulate) {
					// Try take it from the query 
					if(this.$route.query[field.inputName]) {
						fields[field.id] = this.$route.query[field.inputName];
						return;
					}

					if(this.trainingSession[field.inputName]) {
						if(typeof this.trainingSession[field.inputName] === 'object') {
							fields[field.id] = this.trainingSession[field.inputName].map(function(elem){
								return elem.name;
							}).join(",");

						} else {
							fields[field.id] = this.trainingSession[field.inputName];
						}
					}

					if(this.trainingSession[field.inputName + '_count'] && typeof this.trainingSession[field.inputName] === 'object') {
						fields[field.id] = this.trainingSession[field.inputName].length;
					}
				}
			});

			this.fields = fields;
			this.$forceUpdate();
		},

		submit () {
			if(this.submitting) {
				return;
			}

			// this.submitting = true;
			this.errorMessage = '';

			if(!this.fields[8.1]) {
				this.fields[8.1] = 'No';
			}
				
			GravityFormService.submitEntry(this.form.id, this.fields)
				.then(() => {
					this.formSubmitted = true;
					var expire = new Date();
					expire.setHours(23, 59, 59, 0);
					CookieService.set('formsubmitted_' + this.form.id, true, expire);
				}).catch(() => {
					this.errorMessage = 'Sorry, there has been a problem saving your feedback';
				});
		},
	},
};
</script>

<style>
.inline > div > div{
	display: flex;
	align-items: flex-end;
}

.inline > div > div input{
	margin-left: 10px;
}
</style>