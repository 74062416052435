<template>
	<div class="ql-bar">
		<p
			v-if="subTitle"
			class="ql-bar__sub-title"
			v-html="subTitle" />

		<button
			v-if="showBack"
			class="ql-bar__button ripple"
			@click="goBack">
			<img src="@/svg/back.svg">
		</button>

		<button
			class="toggle-mode ripple"
			@click="toggleThemeMode()">
			<span class="toggle-mode__light">Light</span>
			<span class="toggle-mode__blip"/>
			<span class="toggle-mode__dark">Dark</span>
		</button>

		<button
			class="ql-bar__button ripple"
			@click="openSearch">
			<img src="@/svg/search.svg">
		</button>

		<router-link
			:to="{path: '/hr/chat-to-mash/'}"
			class="ql-bar__button ripple">
			<img src="@/svg/chat.svg">
		</router-link>

		
	</div>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
	props: {
		subTitle: {
			required: false,
			type: String,
			default: '',
		},
	},

	computed: {
		showProfile () {
			return this.$route.name === 'home';
		},

		showBack () {
			return this.$route.name !== 'home';
		},

		getTheme () {
			return window.localStorage.getItem('theme') ? window.localStorage.getItem('theme') : 'dark-theme';
		},
	},

	methods: {
		...mapMutations(['openSearch']),

		goBack () {
			this.$router.back();
		},

		toggleThemeMode () {
			// Going from dark to light?
			if (window.localStorage.getItem('theme') === null || window.localStorage.getItem('theme') === 'dark-theme') {
				window.localStorage.setItem('theme', 'light-theme');

				document.body.classList.add('light-theme');
				document.documentElement.classList.add('light-theme');
				return;
			}

			// Light to dark
			window.localStorage.setItem('theme', 'dark-theme');
			document.documentElement.classList.remove('light-theme');
			document.body.classList.remove('light-theme');
		},
	},

};
</script>
