import Vuex from 'vuex';
import Vue from 'vue';
import TandaService from '@/services/TandaService';
import notifications from './notifications';
import trainingProfile from './training-profile';
import AuthService from '@/services/AuthService';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		notifications,
		trainingProfile,
	},
	state: {
		searchItems: [],
		searchOpen: false,
		menuOpen: false,
		errors: {},
		user: {},
		online: true,
		scheduleItems: [],
		leaveRequests: [],
		userList: [],
		tiers: [],
	},

	mutations: {
		setSearchItems (state, items) {
			state.searchItems = items;
		},

		openSearch (state) {
			state.searchOpen = true;
		},

		closeSearch (state) {
			state.searchOpen = false;
		},

		openMenu (state) {
			state.menuOpen = true;
		},

		closeMenu (state) {
			state.menuOpen = false;
		},

		setErrors (state, errors) {
			state.errors = errors;
		},

		clearErrors (state) {
			state.errors = {};
		},

		captureErrors (state, err) {
			if (err.response && err.response.data.errors) {
				state.errors = err.response.data.errors;
				return;
			}

			throw err;
		},

		setUser (state, user) {
			state.user = user;
		},

		setUserlist (state, userlist) {
			state.userList = userlist;
		},

		updateUser (state, user) {
			state.user = user;
			window.localStorage.setItem('user', JSON.stringify(user));
		},

		setOnline (state, status) {
			state.online = status;
		},

		setScheduleItems (state, items) {
			state.scheduleItems = items;
		},

		setLeaveRequests (state, leaveRequests) {
			state.leaveRequests = leaveRequests;
		},

		setTiers(state, tiers) {
			state.tiers = tiers;
		}
	},

	getters: {
		/**
		 * Returns the searchItems filtered by a search keyword or all of them if now search keyword is provided.
		 */
		filteredSearchItems: state => search => {
			if (!search) {
				return state.searchItems;
			}

			let searchWords = search.trim();
			const items = state.searchItems.map(item => {
				item.relavance = 0;
				if (item.title && item.title.toLowerCase().includes(searchWords.toLowerCase())) {
					item.relavance += 10;
				}

				if (item.keywords && typeof item.keywords === 'string' && item.keywords.toLowerCase().includes(searchWords.toLowerCase())) {
					item.relavance += 5;
				}

				return item;
			});

			return items.filter(item => item.relavance > 0).sort((a, b) => {
				if (a.relavance === b.relavance) {
					return 0;
				}

				return a.relavance > b.relavance ? -1 : 1;
			});
		},

		/**
		 * Returns the user filtered by a search keyword or all of them if now search keyword is provided.
		 */
		filteredUsers: state => search => {
			if (!search) {
				return state.userList;
			}

			let searchWords = search.trim();
			return state.userList.filter(item => {
				return item.label.toLowerCase().indexOf(searchWords.toLowerCase()) >= 0 || item.value.toLowerCase().indexOf(searchWords.toLowerCase()) >= 0 ; 
			});
		},

		canPreviewPosts: (state) => {
			return state.user && state.user.preview_permission;
		},

		postStatuses: (state) => {
			let statuses = ['publish'];
			if (state.user && state.user.preview_permission) {
				statuses.push('draft');
			}

			return statuses;
		},
	},

	actions: {
		/**
		 * Attempts to first load the searchItems from localStorage and then if we're online we pull a fresh set down from the API.
		 * @param {*} context
		 */
		loadSearchItems ({ commit, dispatch }) {
			/**
			 * A one-time clear of search items for v2.0 to refresh our
			 * content and new keyword indexing
			 */
			let hasRefreshed = window.localStorage.getItem('refreshedSearchItems');
			if (typeof hasRefreshed === 'undefined') {
				window.localStorage.setItem('refreshedSearchItems', true);
				window.localStorage.removeItem('searchItems');
			}

			// Attempt to load search items from cache in localStorage
			let localItems = window.localStorage.getItem('searchItems');

			if (localItems) {
				localItems = JSON.parse(localItems);
				commit('setSearchItems', localItems);
			}

			// Check if we're online
			if (window.navigator.onLine === false) {
				return;
			}

			if (!AuthService.isLoggedIn()) {
				return;
			}

			// If we're online pull down a fresh set of searchItems
			window.axios
				.get('wp-json/kxi/v1/search')
				.then(res => {
					dispatch('saveSearchItems', res.data);
					commit('setSearchItems', res.data);
				}).catch(() => {});
		},

		loadUser ({commit, dispatch}) {
			let localUser = window.localStorage.getItem('user');

			if (localUser) {
				localUser = JSON.parse(localUser);
				commit('setUser', localUser);
			}

			// Check if we're online
			if (window.navigator.onLine === false) {
				return;
			}

			if (!AuthService.isLoggedIn()) {
				return;
			}

			window.axios.get('wp-json/kxi/v1/profile').then(res => {
				dispatch('saveUser', res.data);
				commit('setUser', res.data);
			}).catch(() => {});
		},

		touchActivity () {
			// Check if we're online
			if (window.navigator.onLine === false) {
				return;
			}

			if (!AuthService.isLoggedIn()) {
				return;
			}	

			let localUser = window.localStorage.getItem('user');

			if(!localUser) {
				return;
			}

			localUser = JSON.parse(localUser);

			const data = {
				'user_id': localUser.id,
			};

			window.axios.post('wp-json/kxi/v1/profile/touch', data);
		},


		loadUsers ( { commit, dispatch}) {
			let localUserlist = window.localStorage.getItem('userList');

			if (localUserlist) {
				localUserlist = JSON.parse(localUserlist);
				commit('setUserlist', localUserlist);
			}

			// Check if we're online
			if (window.navigator.onLine === false) {
				return;
			}

			if (!AuthService.isLoggedIn()) {
				return;
			}

			window.axios.get('wp-json/kxi/v1/timeline/users/').then(res => {
				dispatch('saveUserlist', res.data);
				commit('setUserlist', res.data);
			}).catch(() => {});
		},

		loadAll ({dispatch}) {
			dispatch('loadUser');
			dispatch('loadSearchItems');
			// dispatch('loadScheduleItems');
			// dispatch('loadLeaveRequests');
		},

		/**
		 * Saves searchItems to the localStorage
		 * @param {*} context
		 * @param array items
		 */
		saveSearchItems (context, items) {
			window.localStorage.setItem('searchItems', JSON.stringify(items));
		},

		saveUser (context, user) {
			window.localStorage.setItem('user', JSON.stringify(user));
			window.localStorage.setItem('display_name', user.name);
		},

		saveUserlist (context, userlist) {
			window.localStorage.setItem('userList', JSON.stringify(userlist));
		},

		loadTiers( {commit} ) {
			if (!AuthService.isLoggedIn()) {
				return;
			}

			window.axios.get('wp-json/kxi/v1/options').then(res => {
				commit('setTiers', res.data);
				window.localStorage.setItem('options', JSON.stringify(res.data));
			}).catch(() => {});
		},

		loadScheduleItems ({dispatch, commit}) {
			// Attempt to load search items from cache in localStorage
			let localItems = window.localStorage.getItem('scheduleItems');

			if (localItems) {
				localItems = JSON.parse(localItems);
				commit('setScheduleItems', localItems);
			}

			// Check if we're online
			if (window.navigator.onLine === false) {
				return;
			}

			if (!AuthService.isLoggedIn()) {
				return;
			}

			TandaService.getSchedules().then(res => {
				commit('setScheduleItems', res.data);
				dispatch('saveScheduleItems', res.data);
			});
		},

		saveScheduleItems (context, items) {
			window.localStorage.setItem('setScheduleItems', JSON.stringify(items));
		},

		loadLeaveRequests ({dispatch, commit}) {
			// Attempt to load leave requests from cache in localStorage
			let localItems = window.localStorage.getItem('leaveRequests');

			if (localItems) {
				localItems = JSON.parse(localItems);
				commit('setLeaveRequests', localItems);
			}

			// Check if we're online
			if (window.navigator.onLine === false) {
				return;
			}

			if (!AuthService.isLoggedIn()) {
				return;
			}

			TandaService.getLeaveRequests().then(res => {
				commit('setLeaveRequests', res.data);
				dispatch('saveLeaveRequests', res.data);
			});
		},

		saveLeaveRequests (context, items) {
			window.localStorage.setItem('setLeaveRequests', JSON.stringify(items));
		},
	},
});
