<template>
<div class="timeline__poll__result">

	
  <span class="timeline__poll__result__label"  :class="{ 'timeline__poll__result__label--empty': percentage === 0 }" :style="'width:' + percentage + '%'">
		<span class="timeline__poll__result__text">{{ choice.choice_label }}</span>
	</span>
	<span class="timeline__poll__result__count">{{ percentage }}%</span>
</div>
</template>

<script>
export default {
	props: {
		choice: {
			required: true,
		},
		total: {
			required: true,
		}
	},

	data(){
		return {
			animated: false
		}
	},

	mounted() {
		setTimeout(() => {
			this.animated  = true;
		}, 10);
	},
	computed: {
		percentage() {
			if(!this.animated || !this.choice.selected_by) {
				return 0;
			}
			return Math.round((this.choice.selected_by.length / this.total) * 100);
		},
	}
}
</script>
