<template>
<div class="profile-progress" :class="{'profile-progress--with-title': title}">
	<div>
		<svg
			:height="radius * 2"
			:width="radius * 2"
		>
		<circle
			stroke="#575757"
			fill="transparent"
			:stroke-dasharray="circumference + ' ' + circumference"
			:stroke-width="stroke + 5"
			:r="normalizedRadius"
			:cx="radius"
			:cy="radius"
		/>
		<circle
			class="inner-circle"
			stroke="#74fbb5"
			fill="transparent"
			:stroke-dasharray="circumference + ' ' + circumference"
			:style="{ strokeDashoffset }"
			:stroke-width="stroke"
			:r="normalizedRadius"
			:cx="radius"
			:cy="radius"
		/>
		</svg>
		<p class="profile-progress__text">{{progress }}%</p>
		<div class="profile-progress__title" v-if="title">{{title}}</div>
		<a  href="/training-profile" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" @click="gaEvent()"></a>
	</div>
</div>
</template>

<script>
import {mapState} from 'vuex';

export default {
	props: {
		radius: Number,
		progress: Number,
		stroke: Number,
		title: String,
		userName: String,
		userId: Number,
	},
	data() {
		const normalizedRadius = this.radius - this.stroke * 2;
		const circumference = normalizedRadius * 2 * Math.PI;

		return {
			normalizedRadius,
			circumference
		};
	},
	...mapState(['user']),

	mounted(){
		
	},

	methods: {
		gaEvent() {
			if(this.userName){
				gtag('event', 'click', {'event_category': 'Training Profile', 'event_label': window.localStorage.getItem('display_name')}); // eslint-disable-line no-undef
			}
		}
	},

	computed: {
		strokeDashoffset() {
			let calculus = this.progress;
			if(this.progress > 100) {
				calculus = 100
			}
			return this.circumference - calculus / 100 * this.circumference;
		}	
	}
};
</script>
<style>
.small {
	transform: scale(0.25);
	width: 36px;
	height: 36px;
}

.small .profile-progress__text {
	font-size: 2.6rem;;
}
.profile-progress {
	position: relative;
	flex: 1 1 auto;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.profile-progress--with-title {
	margin-top: 20px;
}

.profile-progress__text {
	position: absolute;
	left: 50%;
	top:50%;
	font-size: 2rem;
	font-family: 'Samsung Sharp', san-serif;
	transform: translate(-50%,-50%);
}

.inner-circle {
	transition: stroke-dashoffset 0.35s;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

.profile-progress__title {
	position: absolute;
	left: 0;
	text-align: center;
	width: 100%;
	top: -20px;
	font-family: 'Samsung Sharp', san-serif;
}
</style>