<template>
	<div class="login container">
		<div class="login__assets">
			<div class="login__header">
				<img
					src="../img/kx-mash-logo.png"
					class="login__logo"
				>
			</div>

			<div class="login__form">
				<form
					action=""
					class="form"
					autocomplete="off"
					@submit.prevent="submit"
				>
					<p class="bold login__hint">If you have forgotten your password fill in the form below and we will send you an email to reset your password</p>

					<div class="form__group">
						<input
							v-show="!sent"
							v-model="username"
							:disabled="loading"
							type="text"
							placeholder="Username"
							name="username"
							class="form__control"
							autocomplete="false"
						>

						<p
							v-if="error"
							class="error"
						>{{ error }}</p>
					</div>

					<button
						v-show="!sent"
						:disabled="sent || loading || !username || username.trim() === ''"
						class="button button--pill login__button bold ripple form__save"
						type="submit"
					>Send Password Reset Link</button>

					<app-error
						v-if="error"
						:text="error"
					/>

					<router-link
						:to="{name: 'login'}"
						class="login__reset-link"
					>Login to Stellar 1Xi</router-link>
				</form>
			</div>
		</div>

		<div class="login__footer">
			<img
				src="../img/footer-logo.png"
				class="login__footer-logo">
		</div>
	</div>
</template>

<script>
import AuthService from '@/services/AuthService';
export default {
	data () {
		return {
			username: '',
			message: '',
			error: '',
			sent: false,
			loading: false,
		};
	},
	methods: {
		submit () {
			if (this.sent || this.loading || !this.username || this.username.trim() === '') {
				return;
			}

			this.message = 'Submitting, please wait...';
			this.error = '';
			this.loading = true;

			AuthService.forgotPassword(this.username).then(res => {
				this.message = res.data.message || '';
				this.sent = true;
				this.loading = false;
			}).catch(err => {
				this.message = '';
				this.error = err.response.data.error || 'Something went wrong, please try again';
				this.loading = false;
				console.error(err);
			});
		},
	},
};
</script>
