<template>
	<div class="leave-request container">
		<section-header
			:layout="{theme: 'chat-to-mash-scheme', style: 'theme', hide_title: false,}"
			:title="{rendered: 'Request Leave'}"
		/>

		<app-quick-links />

		<form
			v-if="!submitted"
			action=""
			class="leave-request__form form"
			@submit.prevent="submit"
		>
			<datetime
				v-model="startDate"
				:min-datetime="minDatetime"
				:max-datetime="maxDatetime"
				type="date"
				placeholder="Start date"
			/>

			<datetime
				v-model="finishDate"
				:min-datetime="startDate"
				:max-datetime="maxDatetime"
				type="date"
				placeholder="End date"
			/>

			<select
				v-model="type"
				name="type"
				class="form__control">
				<option
					:value="null"
					disabled>Choose leave type</option>
				<option
					v-for="(t, index) in types"
					:value="t"
					:key="index">{{ t }}</option>
			</select>

			<textarea
				v-model="reason"
				rows="10"
				class="form__control"
				placeholder="Reason for leave..."/>

			<app-error
				v-if="error"
				:text="error"
				class="leave-request__error"
			/>

			<button
				:disabled="!isValid"
				class="button button--pill">Submit Request</button>
		</form>

		<div
			v-if="submitted"
			class="leave-request__submitted">
			<p class="leave-request__submitted-message">Thanks, your request has been submitted</p>
			<button
				class="button button--pill"
				@click.prevent="resetForm">New Request</button>
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon';
import TandaService from '@/services/TandaService';
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import { required } from 'vuelidate/lib/validators';

export default {
	components: {
		SectionHeader,
	},
	data () {
		return {
			startDate: DateTime.local().toString(),
			finishDate: DateTime.local().plus({days: 1}).toString(),
			types: [
				'Holiday Leave',
			],
			type: null,
			reason: '',
			submitted: false,
			error: null,
		};
	},

	computed: {
		minDatetime () {
			return window.moment().toISOString();
		},
		maxDatetime () {
			return window.moment().add(1, 'year').toISOString();
		},

		datesValid () {
			return !window.moment(this.startDate).isAfter(window.moment(this.finishDate));
		},

		isValid () {
			return this.datesValid && !this.$v.$invalid;
		},
	},

	watch: {
		startDate () {
			if (!this.datesValid) {
				this.finishDate = window.moment(this.startDate).add(1, 'day').toISOString();
			}
		},
	},

	validations () {
		return {
			startDate: {
				required,
			},
			finishDate: {
				required,
			},
			type: {
				required,
			},
			reason: {},
		};
	},

	methods: {
		submit () {
			if (!this.isValid) {
				return;
			}
			this.error = null;
			let formData = {
				start_date: this.startDate,
				finish_date: this.finishDate,
				type: this.type,
				reason: this.reason,
			};

			TandaService.createLeaveRequest(formData).then(res => {
				this.submitted = true;
			}).catch(e => {
				if (!e.response.data || !e.response.data.error) {
					throw e;
				}

				this.error = e.response.data.error;
			});
		},

		resetForm () {
			this.submitted = false;
			this.startDate = DateTime.local().toString();
			this.finishDate = DateTime.local().plus({days: 1}).toString();
			this.type = null;
			this.reason = '';
		},
	},
};
</script>
