<template>
    <div class="public__profile">
        <div class="public__background"></div>
        <div class="container">
            <div v-if="profile">
                <div class="public__header">
                    <img
                        v-if="profile.profile_avatar"
                        :src="profile.profile_avatar"
                        class="profile__avatar avatar-badge public__avatar avatar-badge--with-shadow">
                </div>
                <div class="public__details">

                    <div class="public__name">
                        <h1>{{ profile.name }}</h1>
                        <div class="public__name__group">
                            <p class="public__name__since">{{ profile.role }}</p>
                            <p class="public__description">{{ profile.description }}</p>
                        </div>
                    </div>
                    
                </div>
                <Slideshow
                    :index="imageIndex"
                    :images="gallery"
                    @closeLightbox="lightboxClosed()"
                />
                <div class="profile__tabs" :class="'tab--'+activeTab">
                    <button class="profile__tabs__tab" :class="{'profile__tabs__tab--active' : activeTab === 'gallery'}" @click.prevent="activeTab = 'gallery'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                        </svg>
                        <span>Gallery</span>
                    </button>
                    <button class="profile__tabs__tab" :class="{'profile__tabs__tab--active' : activeTab === 'feed'}" @click.prevent="activeTab = 'feed'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                        </svg>

                        <span>Feed</span>
                    </button>
                </div>
                <div class="public__gallery" v-if="activeTab === 'gallery'">
                    <div
						v-for="(image, idx) in postGalleryImages"
						:key="'public-post-'+idx" @click.prevent="imageIndex = idx;">
                            <img :src="image.gallery" :alt="image.title">
                    </div>
                </div>
                <div v-else-if="activeTab === 'feed'">
                    <TimelineArticle
						v-for="(post) in profile.posts"
						:post="post"
						:user="currentUser"
                        :simple="true"
						:key="'timeline-post-'+post.ID"></TimelineArticle>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slideshow from '@/components/TimelineComponents/SlideshowComponent';
import TimelineArticle from '@/components/TimelineComponents/TimelineArticleComponent';
import {mapState} from 'vuex';

export default {
	data () {
		return {
            profile: null,
            imageIndex: null,
            activeTab: 'gallery',

		};
	},

    components: {
        Slideshow,
        TimelineArticle
    },

	mounted () {
        this.get_user();
	},


	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),

        postGalleryImages() {
            // Loop through our posts and get all the images
            let images = [];
            this.profile.posts.forEach(post => {
                if (post.gallery) {
                    
                    // for each gallery items
                    post.gallery.forEach(gallery => {
                        images.push({
                            title: post.title,
                            gallery: gallery,
                            id: post.id,
                            date: post.date
                        });
                    });
                }
            });
            return images;
        },

        gallery() {
            let images = this.postGalleryImages;
            let strings = [];
            images.forEach(image => {
                strings.push(image.gallery);
            });
            return strings;
        },
	},

    watch:{
        $route (to, from){
            if((from.params && to.params) && from.params.id !== to.params.id) {
                this.get_user();
            }
        }
    } ,

	methods: {
        lightboxClosed() {
			// this.$emit('closeLightbox');
			this.imageIndex = null;
		},

        get_user () {
			let id = this.$route.params.id;
			let url = 'wp-json/kxi/v1/profile/' + id;
			if (isNaN(id)) {
                // Do something if nothing here
				return;
			}
			window.axios.get(url)
				.then(res => {
					if (res.status !== 200) {
						// Show some error or 404 page
						this.$router.replace({name: 'error'});
					}

                    this.profile = res.data;

				}).catch(err => {
					// Show some error or 404 page
					console.log(err);
					this.$router.replace({name: 'error'});
				});
		},
	},
};
</script>
