<template>
	<div>
		<div
			v-if="successMessage"
		>
			<p
				class="chat-to-mash__success"
				v-text="successMessage"
			/>
		</div>

		<div
			v-else
			class="chat-to-mash__item chat-to-mash__item--mail"
		>
			<p
				@click="formOpen = !formOpen"
				v-text="displayText"
			/>

			<form
				v-show="formOpen"
				action="#"
				class="chat-to-mash__form feedback-form"
				@submit.prevent="submit"
			>

				<div
					v-for="(field, index) in layout.form.fields"
					:key="index"
				>
					<div
						v-if="field.type === 'select'"
					>
						<label
							:for="'input-' + field.id"
							v-text="field.label"
						/>
						<select
							v-model="fields[field.id]"
							:id="'input-' + field.id"
							:name="fields[field.id]"
							class="chat-to-mash__form-select"
						>
							<option
								v-for="(option, index) in field.choices"
								:key="index"
								:value="option.value"
								v-text="option.text"
							/>
						</select>
					</div>

					<div
						v-if="field.type === 'textarea'"
						class="feedback-form__textarea-wrap"
					>
						<label
							:for="'input-' + field.id"
							v-text="field.label"
						/>
						<textarea
							id=""
							v-model="fields[field.id]"
							:placeholder="field.placeholder"
							:name="fields[field.id]"
							cols="30"
							rows="5"
							class="feedback-form__textarea chat-to-mash__textarea"
						/>
					</div>
				</div>

				<button
					type="submit"
					class="feedback-form__submit"
				>Send</button>

				<error-component
					v-if="errorMessage"
					:text="errorMessage"
				/>

			</form>
		</div>

	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ErrorComponent from '@/components/ErrorComponent';
import GravityFormService from '@/services/GravityFormService';

export default {
	components: {
		ErrorComponent,
	},

	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			displayText: this.layout.display_text,
			fields: {},
			errorMessage: '',
			successMessage: '',
			formOpen: false,
		};
	},

	validations () {
		let fields = {};

		this.layout.form.fields.forEach(function (field) {
			if (field.isRequired === true) {
				fields[field.id] = {
					required,
				};
			}
		});

		return {
			fields,
		};
	},

	created () {
		let select = this.layout.form.fields.find(function (field, key) {
			return key === 0;
		});

		if (select.choices) {
			this.fields[select.id] = select.choices[0].value;
		}
	},

	methods: {
		submit () {
			this.errorMessage = '';
			this.successMessage = '';

			if (this.$v.$invalid === true) {
				this.errorMessage = 'Please fill out all fields.';
				return;
			}

			GravityFormService.submitEntry(this.layout.form.id, this.fields)
				.then(() => {
					this.successMessage = 'Thank You. Your message has been sent';
				}).catch(() => {
					this.errorMessage = 'Sorry, there has been a problem saving your feedback';
				});
		},
	},
};
</script>
