export default {
	submitEntry (formId, fields) {
		return window.axios.post('wp-json/gf/v2/forms/' + formId + '/entries', fields)
			.then(res => {
				// Handle the submission trigger for this entry
				let entryId = res.data.id;

				return window.axios.post('wp-json/gf/v2/entries/' + entryId + '/notifications');
			}).catch(err => {
				window.bugsnagClient.notify(err);
			});
	},
};
