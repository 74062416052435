<template>
	<div
		class="mixed-content"
		v-html="get_content"
	/>
</template>

<script>

import CookieService from '../../services/CookiesService';

export default {
	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			form: null,
		};
	},

	computed: {
		get_content () {
			return this.layout.mixed_content;
		},
	},

	mounted () {
		this.form = window.$('.mixed-content form');
		this.formSubmit = window.$('.mixed-content form input[type="submit"]');
		this.form.prop('action', process.env.VUE_APP_API_URL);

		this.checkForms();

		this.form.on('submit', this.submitForm);
		this.formSubmit.on('click', this.submitForm);
	},

	methods: {
		submitForm (e) {
			e.preventDefault();
			e.stopPropagation();

			const form = window.$(e.target).closest('form');
			this.addAjaxInput(form);
			const data = form.serialize();
			this.clearFormErrorMessage(form);

			window.axios.post(process.env.VUE_APP_API_URL, data).then(res => {
				let html = window.$(res.data);
				let message = html.find('.gform_confirmation_message').text();
				if (!message) {
					message = 'You gone done someting wrong';
					this.setFormErrorMessage(form, message);
					return;
				}
				this.setFormMessage(form, message);
				this.setFormCookie(form);
			});
		},

		checkForms () {
			this.form.each((i, form) => {
				if (CookieService.get(this.getFormCookieName(form))) {
					this.setFormMessage(form, 'The management team regularly review your feedback and this is very important to us. If you wish to discuss anything further then please don\'t hesitate to speak with your manager.');
				}
			});
		},

		setFormMessage (form, message) {
			window.$(form).html(`
					<header class="feedback-form__header">
						<h3 class="feedback-form__title">Thank you for your feedback.</h3>
						<p class="feedback-form__description">${message}</p>
					</header>`
			);
		},

		setFormErrorMessage (form) {
			window.$(form).find('.gform_footer').prepend(`<p class="error">Something went wrong when submitting the form, please check and try again.</p>`);
		},

		clearFormErrorMessage (form) {
			window.$(form).find('p.error').remove();
		},

		setFormCookie (form) {
			let expire = new Date();
			expire.setHours(23, 59, 59, 0);
			CookieService.set(this.getFormCookieName(form), true, expire);
		},

		getFormCookieName (form) {
			const formId = window.$(form).prop('id').replace('gform_', '');
			return 'formsubmitted_' + formId;
		},

		addAjaxInput (form) {
			const formId = window.$(form).prop('id').replace('gform_', '');
			if (!window.$(form).find('input[type="hidden"][name="gform_ajax"]').length) {
				window.$(form).find('.gform_footer').append('<input type="hidden" name="gform_ajax" value="form_id=' + formId + '&title=1&description=1&tabindex=0">');
			}
		},
	},
};
</script>
