<template>
	<section class="timeline">
		<FlashMessage
			:message="message"
			:show="showFlash"/>
		<transition name="fade">
			<svg
				v-show="loading"
				width="100"
				height="100"
				viewBox="0 0 38 38"
				xmlns="http://www.w3.org/2000/svg"
				stroke="#fff"
				class="timeline__loader">
				<g
					fill="none"
					fill-rule="evenodd"><g
						transform="translate(1 1)"
						stroke-width="2"><circle
							stroke-opacity=".5"
							cx="18"
							cy="18"
							r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 18 18"
									to="360 18 18"
									dur="1s"
									repeatCount="indefinite"/></path></g></g>
			</svg>
		</transition>
		<transition name="fade">
			<div
				v-show="showDelete"
				class="delete-modal">
				<p>Please confirm you wish to remove this post. You will not be able to recover it once it has been deleted</p>
				<div class="delete-modal__actions">
					<button
						class="button button--pill button--grey bold ripple"
						style="margin-right: 10px;"
						@click="showDelete = false">Cancel</button>
					<button
						class="button button--pill button--red bold"
						@click="deletePost">Confirm Delete</button>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div
				v-if="!loading"
				:on-refresh="onRefresh"
				:config="pullToRefreshConfig"
			>
				<div v-if="!posts.length && !loading">
					<div class="timeline__empty">
						<h2 class="timeline__empty--title">It's very quiet</h2>
						<p v-if="bookmarkPage" class="timeline__empty--intro">
							You haven't bookmarked any posts yet. You can bookmark posts by clicking the <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-6" style="width: 16px; height: 16px; position: relative; bottom: -3px">
  								<path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
							</svg> icon on any post.
						</p>
						<p v-else class="timeline__empty--intro">There haven't been any posts yet. Start the conversation using the <span class="timeline__empty--create">+</span> button at the bottom of the app at any time.</p>
					</div>
				</div>

				
				
				<div v-else-if="posts.length">
					<transition-group name="fade" tag="div">
						
						<TimelineArticle
							v-for="(post) in posts"
							:post="post"
							:user="user"
							:key="'timeline-post-'+post.ID"
							@notificationDispatched="notificationDispatched"
							@showDeletePost="showDeletePost"
							@postApproved="postApproved"
							@postDeleted="removePost"
							@commentsToggled="commentsToggled"
							@closeLightbox="closeLb"
							@openLightbox="openLb"
							@postUnbookmarked="postUnbookmarked"
							@postBookmarked="postBookmarked"
						/>
					</transition-group>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<svg
				v-show="loadingMore"
				width="100"
				height="100"
				viewBox="0 0 38 38"
				xmlns="http://www.w3.org/2000/svg"
				stroke="#fff"
				class="timeline__loadingmore">
				<g
					fill="none"
					fill-rule="evenodd"><g
						transform="translate(1 1)"
						stroke-width="2"><circle
							stroke-opacity=".5"
							cx="18"
							cy="18"
							r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 18 18"
									to="360 18 18"
									dur="1s"
									repeatCount="indefinite"/></path></g></g>
			</svg>
		</transition>
		<div v-if="ended && !loading">
			<div class="timeline__empty timeline__empty--nomore">
				<h2 class="timeline__empty--title">That's all folks!</h2>
				<p class="timeline__empty--intro">This is the oldest post we have to show you.</p>
			</div>
		</div>
	</section>
</template>

<script>
import FlashMessage from '@/components/FlashMessageComponent';
import TimelineArticle from '@/components/TimelineComponents/TimelineArticleComponent';
import VuePullRefresh from 'vue-pull-refresh';
import { mapState, mapActions, Time, mapMutations } from 'vuex';

export default {
	components: {
		TimelineArticle,
		FlashMessage,
		'vue-pull-refresh': VuePullRefresh,
	},

	props: {
		bookmarkPage: {
			type: Boolean,
			default: false,
		},
	},

	data () {
		return {
			posts: [],
			message: '',
			showFlash: false,
			loading: true,
			loadingMore: false,
			ended: false,
			batch: 5,
			polling: null,
			isPolling: false,
			showDelete: false,
			deleteId: null,
			offset: null,
			lightboxOpen: false,
			bookmarks: [],
			user: {},
			pullToRefreshConfig: {
				errorLabel: 'Error refreshing Timeline',
				startLabel: 'Pull to refresh Timeline',
				readyLabel: 'Release to reload',
				loadingLabel: 'Loading Timeline',
				pullDownHeight: 150,
			},
		};
	},

	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
	},

	mounted () {
		this.user = {...this.currentUser};
		this.offset = this.batch;
		this.loadTimeline();
		this.getNextPage();

		this.$root.$on('postCreated', (post) => {
			this.addPost(post);
		});
	},

	beforeDestroy () {
		clearInterval(this.polling)
	},

	created () {
		this.pollData();

		window.addEventListener("hashchange", (event) => {
			if(event.oldURL.includes('#lightbox')) {
				this.closeLb();
			}
		});
	},

	watch: {
		currentUser () {
			if (this.currentUser) {
				this.user = {...this.currentUser};
			} else {
				this.user = {};
			}
		},

		online (status) {
			if (status) {
				this.loadUser();
			}
		},
	},


	methods: {
		...mapMutations(['updateUser']),

		pollData () {
			this.polling = setInterval(() => {
				if(!this.loadingMore && !this.isPolling)
				this.loadTimeline();
			}, 120000)
		},

		closeLb() {
			this.lightboxOpen = false;
			history.replaceState(null, null, ' ');
		},

		openLb() {
			this.lightboxOpen = true;

			history.pushState(true, '', '#lightbox')
		},

		showDeletePost (postId) {
			this.showDelete = true;
			this.deleteId = postId;
		},

		deletePost () {
			this.removePost(this.deleteId);
			this.showDelete = false;
			window.axios.delete('wp-json/kxi/v1/timeline/' + this.deleteId).then(res => {
				this.deleteId = null;
				// Succesfully deleted;
			}).catch(err => {
				console.log(err);
			});
		},

		loadTimeline () {
			this.isPolling = true;
			window.axios.get('wp-json/kxi/v1/timeline/?limit=' + (this.offset) + '&bookmarks=' + (this.bookmarkPage) ).then(res => {
				this.isPolling = false;
				this.posts = res.data;
				this.loading = false;
			});
		},

		async onRefresh () {
			if (this.lightboxOpen) {
				return;
			}
			this.posts = [];
			this.loading = true;
			this.ended = false;
			this.offset = this.batch;

			this.loadTimeline();
		},

		removePost (postId) {
			let index = this.posts.findIndex(function (post) {
				return post.ID == postId;
			});

			if (index > -1) {
				this.posts.splice(index, 1);
				this.message = 'Post has successfully been removed.';
				this.showFlashMessage();
			}
		},

		postBookmarked(postId) {
			if(!this.user.bookmarks) {
				this.user.bookmarks = [];
			}

			this.user.bookmarks.push(postId);
			this.updateUser(this.user);
			window.axios.put('wp-json/kxi/v1/timeline/bookmark/' + postId);
		},

		postUnbookmarked (postId) {
			this.user.bookmarks = this.user.bookmarks.filter(bookmark => bookmark !== postId);
			this.updateUser(this.user);
			window.axios.put('wp-json/kxi/v1/timeline/bookmark/' + postId);


			if(!this.bookmarkPage) {
				return;
			}

			let index = this.posts.findIndex(function (post) {
				return post.ID == postId;
			});

			if (index > -1) {
				this.posts.splice(index, 1);
				this.message = 'Post has successfully been removed from your bookmarks.';
				this.showFlashMessage();
			}
		},

		postApproved () {
			this.message = 'Post has been approved.';
			this.showFlashMessage();
		},

		commentsToggled () {
			this.message = 'Comment settings saved';
			this.showFlashMessage();
		},

		addPost (post) {
			this.posts.unshift(post);
			this.message = 'Your post was created.';
			this.showFlashMessage();
			window.scrollTo({top: 0, behavior: 'smooth'});
		},

		notificationDispatched () {
			this.message = 'Notifications have been queued for dispatch.';
			this.showFlashMessage();
		},

		showFlashMessage () {
			this.showFlash = true;

			setTimeout(() => {
				this.showFlash = false;
			}, 5000);
		},

		getNextPage () {
			window.onscroll = () => {
				if(window.location.pathname !== '/') {
					return;
				}

				let bottomOfWindow = (document.documentElement.scrollHeight - (document.documentElement.scrollTop + 1000 )) <= document.documentElement.clientHeight;
				if (bottomOfWindow && !this.loadingMore && !this.ended && !this.isPolling) {
					this.loadingMore = true;

					window.axios.get('wp-json/kxi/v1/timeline/?type=timeline&offset=' + this.offset + '&limit=' + this.batch).then(res => {

						this.loadingMore = false;

						if (res.data.length < 1) {
							this.ended = true;
							return;
						}

						this.offset += this.batch;
						this.posts = this.posts.concat(res.data);
					}).catch(() => {
						this.loadingMore = false;
					});
				}
			};
		},
	},

};
</script>

<style>

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

/* Add CSS for transition */
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
	opacity: 0;
}
</style>
