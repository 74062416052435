<template>
	<div
		class="notification-row"
		@click="onClick">
		<div
			v-if="notification.image_url"
			class="notification-row__image">
			<img
				:src="notification.image_url"
				:alt="notification.title">
		</div>
		<div class="notification-row__body">
			<h3 class="notification-row__title">{{ notification.title }}</h3>
			<div
				class="notification-row__content"
				v-html="notification.content"/>
			<div class="notification-row__time">{{ time }}</div>
		</div>
		<div class="notification-row__status">
			<i
				v-if="!notification.is_read"
				class="notification-row__unread"/>
			<i
				v-if="notification.is_read"
				class="notification-row__read"/>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
	props: {
		notification: {
			required: true,
			type: Object,
		},
	},
	computed: {
		time () {
			if (!this.notification.time) {
				return '';
			}

			return moment(this.notification.time).fromNow();
		},
	},
	methods: {
		...mapActions('notifications', [
			'markAsRead',
		]),
		onClick () {
			if (!this.notification.is_read) {
				this.notification.is_read = 1;
				this.markAsRead([this.notification.id]);
			}
			if (!this.notification.url || this.notification.url === '') {
				return;
			}

			if (this.notification.url.indexOf('://') > 0 || this.notification.url.indexOf('//') === 0 ) {
				let notificationLink = new URL(this.notification.url);

				// Internal link with a full link?
				if(notificationLink.hostname === location.hostname) {
					this.$router.push({path: notificationLink.pathname+notificationLink.search});
					return;
				}

				return window.location.href = this.notification.url;
			}

			this.$router.push({path: this.notification.url});
		},
	},
};
</script>
