<template>
	<div class="app-nav">
		<div class="app-nav__container">
			<nav-icon
				:active="routeIsActive('home')"
				name="home"
				path="/"
				title="Home"
			/>
			<nav-icon
				:active="routeIsActive('content')"
				name="book"
				path="/handbooks"
				title="Handbooks"
			/>

			<nav-create />

			<nav-icon
				:active="routeIsActive('insights')"
				name="reel"
				path="/insights"
				title="Insights"
			/>

			<nav-icon
				:active="routeIsActive('bookmarks')"
				name="bookmark"
				path="/bookmarks"
				title="bookmarks"
			/>
		</div>
	</div>
</template>

<script>

import NavIcon from '@/components/NavIconComponent';
import NavCreate from '@/components/NavCreateComponent';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
	components: {
		NavIcon,
		NavCreate,
	},

	data () {
		return {
			searching: false,
			notificationInterval: null,
		};
	},

	mounted () {
		// Check for notifications every 5 mins
		this.notificationInterval = window.setInterval(() => {
			this.loadNotifications();
		}, 5 * 60 * 1000);
	},

	beforeDestroy () {
		window.clearInterval(this.notificationInterval);
	},


	computed: {
		currentRouteName () {
			return this.$route.name;
		},

		...mapGetters('notifications', [
			'hasUnread',
		]),
	},

	methods: {
		...mapActions('notifications', [
			'loadNotifications',
		]),

		routeIsActive (routeName) {
			return routeName == this.currentRouteName;
		},

		
	},
};
</script>
