<template>
	<div
		class="holiday-balance">
		<p class="holiday-balance__intro">{{ layout.intro_text }}</p>
		<span
			v-if="user.holiday_balance !== 'N/A'"
			class="holiday-balance__hours"><strong>{{ user.holiday_balance }}</strong> <span class="holiday-balance__hours__label">Hours</span></span>
		<span
			v-else
			class="holiday-balance__na">Not Available</span>
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
		};
	},

	computed: {
		...mapState(['user']),
	},
};
</script>
