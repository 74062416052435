import TrainingProfileService from '@/services/TrainingProfileService';
import AuthService from '@/services/AuthService';

export default {
	namespaced: true,
	state: () => {
		return {
			profile: [],
			loaded: false,
		};
	},
	actions: {
		loadProfile ({commit}) {
			if (!AuthService.isLoggedIn()) {
				return;
			}

			let localUser = window.localStorage.getItem('user');
			if(localUser) {
				localUser = JSON.parse(localUser);
				TrainingProfileService.index(localUser.id).then(res => {
					commit('setProfile', res.data);
					commit('setLoaded');
				});
			}
		},
	},
	mutations: {
		setProfile (state, profile) {
			state.profile = profile;
		},
		setLoaded (state, loaded = true) {
			state.loaded = loaded;
		},
	},
	getters: {
		profile: state => state.profile,
	},
};
