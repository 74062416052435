<template>
	<div class="submit-post container">
		<section-header
			:layout="{theme: 'chat-to-mash-scheme', style: 'theme', hide_title: false,}"
			:title="{ rendered: 'New KX Spotlight' }"
		/>
		
		<div class="mixed-content" v-if="isInitial">
			<p class="mb-0" style="margin-bottom: 0">Complete the form below to submit a post to the KX Spotlights.</p>
			</div>
		<div class="form">
			<div class="saving" v-if="isSaving">
				<div class="block">Submitting. Please don't navigate away...</div>
				<svg width="100" height="100" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
					<g fill="none" fill-rule="evenodd">
						<g transform="translate(1 1)" stroke-width="2">
							<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
							<path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 18 18"
									to="360 18 18"
									dur="1s"
									repeatCount="indefinite"/>
							</path>
						</g>
					</g>
				</svg>
			</div>

			<div v-else-if="isSuccess" class="success-message mixed-content">
				<p>Thank you, we now have your KX Spotlights post and a member of the team will review it shortly. </p>
				<button class="button button--pill" style="font-size: 1.2rem" type="button" @click="reset">Create Another KX Spotlight</button>
			</div>
			<form
				v-else
				action="#"
				class="feedback-form"
				@submit.prevent="submit()">

				<div class="form__group">
					<input
						v-model="form.title"
						type="text"
						class="form__control"
						placeholder="Article Title"
						name="title">
					<app-error name="title"/>
				</div>
				<div class="form__group">
					<div class="dropbox">
						<input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
						accept="image/*" class="input-file">
						<p v-if="hasFile">
							<span>{{fileName}}</span><br /> Selected as Featured Image
						</p>
						<p v-if="!hasFile">
							<span class="font-bold">Featured Image</span><br />
							Drag your file(s) here to begin<br> or click
						</p>
					</div>
				</div>
				<div class="editor-wrap">
				<editor
					:api-key="tinyApi"
					v-model="form.content"
					:init="{
						height: 500,
						menubar: false,
						plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
						],
						toolbar:
						'undo redo | formatselect | bold italic backcolor | \
						alignleft aligncenter alignright alignjustify | \
						bullist numlist outdent indent | removeformat | help'
					}"/>
				</div>

				<button class="button button--pill" type="submit">Submit</button>
			</form>
		</div>

	</div>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
 import Editor from '@tinymce/tinymce-vue'
  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
	components: {
		SectionHeader,
		'editor': Editor
	},
	props: {
	},

	data () {
		return {
			successMessage: null,
			errorMessage: null,
			tinyApi: 'n4k2l8d6e5dnosy9468umm6ivw1jmwjr73shwfeb2ffdjplg',
			uploadError: null,
			currentStatus: STATUS_INITIAL,
			hasFile: false,
			uploadFieldName: 'image',
			fileName: null,
			formData: new FormData(),
			form: {
				title: null,
				content: null,
			},
			 config: {
				// Set the image upload parameter.
				imageUploadParam: 'image_param',
				// Set the image upload URL.
				imageUploadURL: process.env.VUE_APP_API_URL+'wp-json/kxi/v1/posts/upload',
				height: 300,
				events: {
				}
			},
		};
	},
 	mounted() {

		  let user = JSON.parse(window.localStorage.getItem('user'));

		  if(user.role !== 'administrator' && user.role !== 'contributor' && user.role !== 'trainer') {
			  window.location.href = '/';
		  }
      this.reset();
    },

	methods: {
		submit() {

			this.currentStatus = STATUS_SAVING;

			this.formData.append('title', this.form.title);
			this.formData.append('content', this.form.content);
			
			window.axios.post('wp-json/kxi/v1/posts/submit/', this.formData).then(res => {
				this.successMessage = 'Thank You. Your post has been submitted';
				  this.currentStatus = STATUS_SUCCESS;

			}).catch(err => {
				this.errorMessage = 'Sorry, there has been a problem saving your post';
				this.currentStatus = STATUS_FAILED;
			});
		},
	
		reset() {
			this.currentStatus = STATUS_INITIAL;
			this.uploadError = null;
		},

		filesChange(fieldName, fileList) {
			if (!fileList.length) return;

			this.hasFile = true;
			Array
			.from(Array(fileList.length).keys())
			.map(x => {
				this.formData.append(fieldName, fileList[x], fileList[x].name);
				this.fileName = fileList[x].name;
			});
		}
	},

	computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
};
</script>

<style scoped>
.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    /* background: lightcyan; */
    color: #fff;
    padding: 10px 10px;
    min-height: 100px; /* minimum height */
    position: relative;
    cursor: pointer;
	margin: 2rem 0;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    outline: 2px solid #02b3e3; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2rem;
    text-align: center;
    padding: 50px 0;
  }

  .dropbox p span {
		font-weight: bold;
}

.editor-wrap {
	margin: 2rem 0;
}

.saving {
text-align: center;
border: 1px solid #fff;
padding: 2rem;
font-size: 1.6rem;
margin-top: 2rem;
}

.light-theme .saving {
	border-color: #333;
}

.saving .block {
	margin: 0 0 2rem 0;
}

.success-message {
	color: #74fbb5;
}

.light-theme .dropbox p {
	color: #333;
}
</style>