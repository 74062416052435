<!-- eslint-disable vue/html-self-closing -->
<template>
	<section
		:class="[{'post-update--open' : open}, {'post-update--saving' : isSaving}]"
		class="post-update"
	>
		<div
			class="post-update__header"
			@click="openPost"
		>
			Post an update to the Stellar 1Xi timeline
		</div>
		<div class="post-update__inner">
			<button
				class="icon-button post-update__close"
				@click="openPost"
			>
				<svg
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.8125 10.8125L17.1875 17.1875M17.1875 10.8125L10.8125 17.1875M26.75 14C26.75 15.6744 26.4202 17.3323 25.7795 18.8792C25.1387 20.4261 24.1996 21.8317 23.0156 23.0156C21.8317 24.1996 20.4261 25.1387 18.8792 25.7795C17.3323 26.4202 15.6744 26.75 14 26.75C12.3256 26.75 10.6677 26.4202 9.12079 25.7795C7.57388 25.1387 6.16834 24.1996 4.98439 23.0156C3.80044 21.8317 2.86128 20.4261 2.22054 18.8792C1.57979 17.3323 1.25 15.6744 1.25 14C1.25 10.6185 2.5933 7.37548 4.98439 4.98439C7.37548 2.5933 10.6185 1.25 14 1.25C17.3815 1.25 20.6245 2.5933 23.0156 4.98439C25.4067 7.37548 26.75 10.6185 26.75 14Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>

			<form @submit.prevent="submit">
				<div class="post-update__body" :class="{'post-update__body--has-poll': form.is_poll}">
					<TipTap @update="setNewValue" :value="form.content" :placeholder="form.is_poll ? 'What is the question for this poll?' : 'What would you like to share?'"/>
					<div
					 	v-if="form.is_poll"
						class="post-update__body--polls">
						<div class="post-update__poll">
							
							<div class="post-update__poll__choices">
								<div
								v-for="(choice, k) in choices"
								:key="'choice-'+k"
								class="post-update__poll__choices__choice">
									<button type="button" :disabled="choices.length < 3 " class="button post-update__poll__remove" @click.prevent="choices.splice(k, 1)">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width:16px; height: 16px;">
  											<path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>
									</button>
									<input
										required="required"
										v-model="choices[k]"
										:placeholder="'Choice '+ (k + 1)"
										class="form__control"
										type="text" />
								
								</div>
								<button type="button" v-if="choices.length < 4" class="post-update__poll__add" @click.prevent="choices.push('')"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path></svg></button>
							</div>

							<div class="post-update__poll__expiry">
								<label for="expiry_date">Poll End Date</label>
								<input name="expiry_date" id="expiry_date" required="required" type="datetime-local" v-model="form.expiry_date" class="form__control" />
							</div>
						</div>
						
					</div>

				</div>

				<div class="post-update__footer">
					<p
						v-show="addingLink && urlError"
						class="post-update__addlink__error">{{ urlError }}</p>
					<div
						v-show="addingLink"
						class="post-update__addlink"
					>
						<input
							ref="link"
							v-model="form.link"
							type="text"
							placeholder="Enter the url of the website to link to e.g. https://www.google.com"
							class="form__control"
							@keydown.enter.prevent=""
						>


						<button
							type="button"
							class="button icon-button post-update__addlink__remove"
							@click="removeLink"><svg
								xmlns="http://www.w3.org/2000/svg"
								fill="#fff"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
						</button>
					</div>



					<div
						v-if="hasImage || hasFile"
						class="post-update__attachments"
					>
						<div
							v-for="(img, ikey) in images"
							:key="'image-'+ ikey"
							class="post-update__attachments__image__wrap">
							<img
								:src="previews[ikey]"
								class="post-update__attachments__image" />
							<button
								type="button"
								class="post-update__attachments__image__remove"
								@click.prevent="removeImage(ikey)">
								<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="size-5">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
								</svg>

							</button>
						</div>

						<div
							v-if="fileName"
							class="post-update__attachments__file"
						>
							<svg
								width="18"
								height="22"
								viewBox="0 0 18 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.8437 10.6774L7.79183 17.7293C7.01824 18.5029 5.96902 18.9375 4.87499 18.9375C3.78097 18.9375 2.73175 18.5029 1.95816 17.7293C1.18457 16.9557 0.749969 15.9065 0.749969 14.8125C0.749969 13.7185 1.18457 12.6692 1.95816 11.8957L11.9865 1.86732C12.2419 1.612 12.5452 1.40949 12.8789 1.27136C13.2126 1.13322 13.5702 1.06217 13.9314 1.06226C14.2926 1.06234 14.6502 1.13356 14.9838 1.27185C15.3175 1.41014 15.6206 1.6128 15.8759 1.86824C16.1312 2.12368 16.3337 2.42691 16.4719 2.76062C16.61 3.09433 16.6811 3.45197 16.681 3.81314C16.6809 4.1743 16.6097 4.53192 16.4714 4.86556C16.3331 5.1992 16.1304 5.50233 15.875 5.75766L5.83933 15.7933M5.84758 15.7842L5.83841 15.7933M11.0625 6.68074L3.90333 13.8399C3.65154 14.0981 3.51129 14.4449 3.51279 14.8056C3.5143 15.1662 3.65745 15.5119 3.91139 15.768C4.16533 16.0241 4.50973 16.1702 4.87036 16.1747C5.23099 16.1793 5.57898 16.042 5.83933 15.7924"
									stroke="currentcolor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"/>
							</svg> {{ fileName }}
							<button
								type="button"
								class="post-update__attachments__file__remove"
								@click.prevent="removeFile()">
								<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="size-5">
									<path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
								</svg>
							</button>
						</div>
					</div>

					<div class="post-update__footer-actions">
						
						<label
							v-show="!isSaving && !isSaved"
							:class="{'active' : hasImage}"
							for="image"
							class="icon-button"
							style="margin-right: .75rem;"
						>
							<input
								id="image"
								type="file"
								multiple="true"
								name="image"
								style="display:none"
								@change="filesChange($event.target.name, $event.target.files);"
							>
							<svg
								width="22"
								height="18"
								viewBox="0 0 22 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style="position: relative; top: 2px;"
							>
								<path
									d="M1.25 12.75L6.409 7.591C6.61793 7.38206 6.86597 7.21633 7.13896 7.10325C7.41194 6.99018 7.70452 6.93198 8 6.93198C8.29548 6.93198 8.58806 6.99018 8.86104 7.10325C9.13403 7.21633 9.38207 7.38206 9.591 7.591L14.75 12.75M13.25 11.25L14.659 9.841C14.8679 9.63206 15.116 9.46633 15.389 9.35325C15.6619 9.24018 15.9545 9.18198 16.25 9.18198C16.5455 9.18198 16.8381 9.24018 17.111 9.35325C17.384 9.46633 17.6321 9.63206 17.841 9.841L20.75 12.75M2.75 16.5H19.25C19.6478 16.5 20.0294 16.342 20.3107 16.0607C20.592 15.7794 20.75 15.3978 20.75 15V3C20.75 2.60218 20.592 2.22064 20.3107 1.93934C20.0294 1.65804 19.6478 1.5 19.25 1.5H2.75C2.35218 1.5 1.97064 1.65804 1.68934 1.93934C1.40804 2.22064 1.25 2.60218 1.25 3V15C1.25 15.3978 1.40804 15.7794 1.68934 16.0607C1.97064 16.342 2.35218 16.5 2.75 16.5ZM13.25 5.25H13.258V5.258H13.25V5.25ZM13.625 5.25C13.625 5.34946 13.5855 5.44484 13.5152 5.51517C13.4448 5.58549 13.3495 5.625 13.25 5.625C13.1505 5.625 13.0552 5.58549 12.9848 5.51517C12.9145 5.44484 12.875 5.34946 12.875 5.25C12.875 5.15054 12.9145 5.05516 12.9848 4.98484C13.0552 4.91451 13.1505 4.875 13.25 4.875C13.3495 4.875 13.4448 4.91451 13.5152 4.98484C13.5855 5.05516 13.625 5.15054 13.625 5.25V5.25Z"
									stroke="currentcolor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"/>
							</svg>
						</label>

						<button
							v-show="!isSaving && !isSaved"
							:class="{'active' : form.is_poll}"
							class="icon-button"
							type="button"
							@click="form.is_poll = !form.is_poll">
							<svg viewBox="0 0 24 24" aria-hidden="true"><g><path fill="currentColor" d="M6 5c-1.1 0-2 .895-2 2s.9 2 2 2 2-.895 2-2-.9-2-2-2zM2 7c0-2.209 1.79-4 4-4s4 1.791 4 4-1.79 4-4 4-4-1.791-4-4zm20 1H12V6h10v2zM6 15c-1.1 0-2 .895-2 2s.9 2 2 2 2-.895 2-2-.9-2-2-2zm-4 2c0-2.209 1.79-4 4-4s4 1.791 4 4-1.79 4-4 4-4-1.791-4-4zm20 1H12v-2h10v2zM7 7c0 .552-.45 1-1 1s-1-.448-1-1 .45-1 1-1 1 .448 1 1z"></path></g></svg>
						</button>

						<button
							v-show="!isSaving && !isSaved"
							:class="{'active' : addingLink}"
							class="icon-button"
							type="button"
							@click="showAddLink">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M11.0908 6.96399C11.6865 7.2484 12.2052 7.6716 12.6034 8.19805C13.0016 8.7245 13.2677 9.33882 13.3792 9.98941C13.4908 10.64 13.4446 11.3079 13.2445 11.9369C13.0444 12.5659 12.6962 13.1377 12.2293 13.6043L8.10433 17.7293C7.33074 18.5029 6.28152 18.9375 5.18749 18.9375C4.09347 18.9375 3.04425 18.5029 2.27066 17.7293C1.49707 16.9557 1.06247 15.9065 1.06247 14.8125C1.06247 13.7185 1.49707 12.6693 2.27066 11.8957L3.88124 10.2851M16.1187 9.71491L17.7293 8.10433C18.5029 7.33074 18.9375 6.28152 18.9375 5.18749C18.9375 4.09347 18.5029 3.04425 17.7293 2.27066C16.9557 1.49707 15.9065 1.06247 14.8125 1.06247C13.7185 1.06247 12.6693 1.49707 11.8957 2.27066L7.77066 6.39566C7.30375 6.86225 6.95562 7.43407 6.75553 8.0631C6.55544 8.69213 6.50922 9.35999 6.62077 10.0106C6.73231 10.6612 6.99836 11.2755 7.39656 11.8019C7.79476 12.3284 8.31349 12.7516 8.90916 13.036"
									stroke="currentcolor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"/>
							</svg>
						</button>

						<label
							v-if="isAdmin"
							:class="{'active' : form.as_admin}"
							for="as_admin"
							class="icon-button icon-button--withtext as_admin"
							type="button"
						>
							Admin Post
							<input
								id="as_admin"
								v-model="form.as_admin"
								type="checkbox"
								name="as_admin"
							>
						</label>

						<label
							v-if="isAdmin"
							:class="{'active' : form.pin}"
							for="pin"
							class="icon-button icon-button--withtext pin"
							type="button"
						>
							Pin
							<input
								id="pin"
								v-model="form.pin"
								type="checkbox"
								name="pin"
							>
						</label>
					</div>

					<div v-if="form.pin">
						<input name="pin_expiry_date" id="pin_expiry_date" required="required" type="datetime-local" v-model="form.pin_expiry" class="form__control form__control--date-time" />
					</div>

					<div class="post-update__footer__submit">
						<p v-if="errorMessage" class="post-update__footer__error">{{ errorMessage }}</p>
						<transition name="fade">
							<button
								v-if="!isSaving && !isSaved"
								type="submit"
								class="button button--pill form__save"
							>Post to Timeline</button>

							<div
								v-else-if="isSaved"
								class="post-update__footer__saved"
							>
								{{ successMessage }}
							</div>

							<div
								v-else-if="isSaving"
								class="post-update__footer__saving"
							>
								<span>Hold tight, we're sending your post... </span>
								<svg
									width="38"
									height="38"
									viewBox="0 0 38 38"
									xmlns="http://www.w3.org/2000/svg"
									stroke="currentColor"
								>
									<g
										fill="none"
										fill-rule="evenodd">
										<g
											transform="translate(1 1)"
											stroke-width="2">
											<circle
												stroke-opacity=".5"
												cx="18"
												cy="18"
												r="18"/>
											<path d="M36 18c0-9.94-8.06-18-18-18">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 18 18"
													to="360 18 18"
													dur="1s"
													repeatCount="indefinite"/>
											</path>
										</g>
									</g>
								</svg>
							</div>
						</transition>
					</div>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
import Mention from '@/components/MentionComponent';
import { DateTime } from 'luxon';
import TipTap from '@/components/TipTap.vue'
export default {

	components: {
		Mention,
		TipTap
	},

	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			formData: new FormData(),
			form: {
				content: null,
				link: '',
				as_admin: false,
				is_poll: false,
				pin: false,
			},
			choices: ['', ''],
			open: false,
			errorMessage: null,
			urlError: false,
			addingLink: false,
			fileName: null,
			isSaving: false,
			isSaved: false,
			image: null,
			images: [],
			hasFile: false,
			previews: [],
			hasImage: false,
			hasLink: false,
			successMessage: 'Great News your post has been submitted!',
		};
	},

	computed: {
		imagePreview () {
			if (this.image) {
				return this.image ? URL.createObjectURL(this.image) : null;
			}

			return null;
		},

		isAdmin () {
			if (this.user.role === 'editor' || this.user.role === 'administrator') {
				return true;
			}

			return false;
		},
	},

	mounted () {
		this.$root.$on('postUpdate', () => {
			// Get the current path
			let currentPath = this.$route.path;

			// Trim trailing slash
			currentPath = currentPath.replace(/\/$/, '');

			// Push the new path
			this.$router.push({ path: currentPath + '#postUpdate' }).catch(()=>{});

			this.open = false;
			this.openPost();
		});
	},

	watch: {
    	$route(to, from) {
			if(to.hash === '#postUpdate') {
				// Open Post
				this.open = false;
				this.openPost();
			} else if(from.hash === '#postUpdate') {
				// Close Post
				this.open = true;
				this.openPost();
			}
    	},
  	},

	methods: {
		openPost () {
			if(!this.form.is_poll && this.$refs.mention) {
				this.$refs.mention.reset();
			}
			// Toggle open
			this.open = !this.open;

			if (this.open) {
				document.body.classList.add('fixed');
			} else {
				document.body.classList.remove('fixed');

				// Reset the form
				this.reset();

				// Get the current path
				let currentPath = this.$route.path;
				// Trim trailing slash
				currentPath = currentPath.replace(/\/$/, '');
				this.$router.push({ path: currentPath }).catch(()=>{});
			}
		},


		removeImage (idx) {
			this.images.splice(idx, 1);
			this.previews.splice(idx, 1);

			this.formData.delete('image[' + idx + ']');
			if (this.images.length < 1) {
				this.hasImage = false;
			}

			// this.filesChange('image', this.images);
		},

		removeFile () {
			this.formData.delete('attachment[0]');
			this.hasFile = false;
			this.$refs.fileUpload.value = null;
		},

		genImagePreview (file) {
			console.log('why is this getting called all the time?')
			console.log('test', file);
			let fileType = file.type
			if( fileType.includes('video')) {
				return 'https://cms.stellarkx.com/wp-content/uploads/2023/08/Video-Placeholder.png';
			}

			if (file) {
				return URL.createObjectURL(file);
			}

			return null;
		},

		setNewValue (val) {
			this.form.content = val;
		},

		showAddLink () {
			this.addingLink = true;
			setTimeout(() => {
				this.$refs.link.focus();
			}, 50);
		},

		removeLink () {
			this.addingLink = false;
			this.form.link = null;
		},

		submit () {
			this.urlError = false;

			// Test the link if adding
			if (this.addingLink) {
				if (!this.isUrl(this.form.link)) {

					if (!this.form.link.startsWith('http')) {
						this.form.link = 'http://' + this.form.link;
						if (!this.isUrl(this.form.link)) {
							this.urlError = 'Please provide a valid url for your link';
							return;
						}
					} else {
						this.urlError = 'Please provide a valid url for your link';
						return;
					}
				}
			}

			if (!this.form.content && !this.hasImage && !this.hasFile) {
				return;
			}

			this.isSaving = true;
			this.formData.append('title', this.form.title);
			this.formData.append('content', this.form.content);
			this.formData.append('name', this.user.name);
			this.formData.append('id', this.user.id);
			this.formData.append('link', this.form.link);
			this.formData.append('as_admin', this.form.as_admin);
			this.formData.append('pin', this.form.pin);
			this.formData.append('is_poll', this.form.is_poll);
			this.formData.append('choices', this.choices);
			this.formData.append('expiry_date', this.form.expiry_date);


			window.axios.post('wp-json/kxi/v1/timeline/submit/', this.formData, {
				headers: {
        			"Content-Type": "multipart/form-data"
				},
			}).then(res => {
				this.successMessage = 'Thank You. Your post has been submitted';
				this.$root.$emit('postCreated', res.data);
				this.isSaving = false;
				this.isSaved = true;
				this.reset();
				this.open = false;
				window.scrollTo({ top: 0, behavior: 'smooth' });
				if(window.location.pathname !== '/') {
					window.location.href = '/';
				}
				this.$router.push({ path: '/' }).catch(()=>{});
			}).catch(err => {
				console.error(err);
				this.open = false;
				this.isSaving = false;
				// @TODO error message show
				this.errorMessage = 'Sorry, there has been a problem saving your post';
			});
		},

		reset () {
			this.formData = new FormData();

			this.uploadError = null;
			this.form = {
				content: null,
				link: '',
				as_admin: false,
				is_poll: false,
				pin: false,
			};

			this.open = false;
			this.successMessage = null;
			this.errorMessage = null;
			this.isSaving = false;
			this.isSaved = false;
			this.hasFile = false;
			this.hasImage = false;
			this.hasLink = false;
			this.choices = [];

		},

		filesChange (fieldName, fileList) {
			const MAX_FILE_SIZE = 32 * 1024 * 1024; // 32MB limit

			if (!fileList.length) {
				if (fieldName === 'image') {
					this.hasImage = false;
				}

				return;
			}

			for (let i = 0; i < fileList.length; i++) {
				const file = fileList[i];
				if (file.size > MAX_FILE_SIZE) {
					this.errorMessage = `The file "${file.name}" exceeds the maximum size limit of 32MB.`;
					return; // Stop further processing
				}
			}


			if (fieldName === 'image') {
				this.hasImage = true;
				this.image = fileList[0];
			}

			if (fieldName === 'attachment') {
				this.hasFile = true;
				this.fileName = fileList[0].name;
			}

			this.previews = [];
			this.images = [];

			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					if (fieldName === 'image') {
						this.images.push(fileList[x]);
					}

					this.previews.push(this.genImagePreview(fileList[x]));

					this.formData.append(fieldName + '[' + x + ']', fileList[x], fileList[x].name);
				});
		},

		/**
		 * Loosely validate a URL `string`.
		 *
		 * @param {String} string
		 * @return {Boolean}
		 */

		isUrl (string) {
			var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
			var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/
			var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

			if (typeof string !== 'string' ) {
				return false;
			}

			var match = string.match(protocolAndDomainRE);
			if (!match) {
				return false;
			}

			var everythingAfterProtocol = match[1];
			if (!everythingAfterProtocol) {
				return false;
			}

			if (localhostDomainRE.test(everythingAfterProtocol) ||
				nonLocalhostDomainRE.test(everythingAfterProtocol)) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style>

</style>
