<template>
	<div class="mixed-content-grid">
		<mixed-content
			v-for="(tile, index) in get_tiles"
			:key="index"
			:index="index"
			:layout="tile"
			class="mixed-content-grid__item"
		/>
	</div>
</template>

<script>
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';

export default {
	components: {
		MixedContent,
	},

	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	computed: {
		get_tiles () {
			return this.layout.squares;
		},
	},
};
</script>
