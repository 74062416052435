<template>
	<section class="container layout">
		<FlashMessage
			:message="message"
			:show="showFlash"/>
			<div class="timeline__single">
				<div class="timeline__single__header">
					<router-link :to="{name: 'home'}" 
						class="icon-button">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" style="width: 24px;">
							<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
						</svg>
						<span>Back </span>
					</router-link> 
				</div>
		<Transition name="fade">
			<TimelineArticle
				v-if="currentUser && post"
				:post="post"
				:user="currentUser"
				:key="'timeline-post-'+post.ID"
				@postApproved="postApproved"
				@postDeleted="removePost"
				@commentsToggled="commentsToggled"
			/>
		</Transition>

		<transition name="fade">
			<div v-if="!loading && !post" class="timeline__single__missing">
				<p>Oops, It looks like this post no longer exists, it most likely has been deleted. If you believe this should exist, please contact an administrator to investigate.</p>
				<a href="/"
					class="button button--pill ripple form__save">Back to Timeline</a>
			</div>

			

		</transition>

		<transition name="fade">
				<svg
					v-show="loading"
					width="100"
					height="100"
					viewBox="0 0 38 38"
					xmlns="http://www.w3.org/2000/svg"
					stroke="#fff"
					class="timeline__loader">
					<g
						fill="none"
						fill-rule="evenodd"><g
							transform="translate(1 1)"
							stroke-width="2"><circle
								stroke-opacity=".5"
								cx="18"
								cy="18"
								r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
									<animateTransform
										attributeName="transform"
										type="rotate"
										from="0 18 18"
										to="360 18 18"
										dur="1s"
										repeatCount="indefinite"/></path></g></g>
				</svg>
			</transition>
	</div>
	</section>
</template>
<script>
import { mapState } from 'vuex';
import FlashMessage from '@/components/FlashMessageComponent';
import TimelineArticle from '@/components/TimelineComponents/TimelineArticleComponent';

export default {
	components: {
		TimelineArticle,
		FlashMessage,
	},


	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
	},

	data() {
		return {
			postId: this.$route.params.id,
			post: null,
			loading: true,
			message: '',
			showFlash: false,
		}
	},

	beforeMount() {
		window.axios.get('wp-json/kxi/v1/timeline/?limit=1&pid=' + this.postId).then(res => {
			this.loading = false;
			if(res.data && typeof res.data[0] !== 'undefined'){
				this.post = res.data[0];
			} else {
				this.post = null;
			}
		});
	},

	methods: {
		removePost (postId) {
			this.message = 'Post has successfully been removed. Redirecting you to the homepage';
			this.showFlashMessage();
			setTimeout(() => {
				this.$router.push({name : 'home'});
			}, 1000);
		},

		postApproved () {
			this.message = 'Post has been approved.';
			this.showFlashMessage();
		},

		commentsToggled () {
			this.message = 'Comment settings saved';
			this.showFlashMessage();
		},

		showFlashMessage () {
			this.showFlash = true;

			setTimeout(() => {
				this.showFlash = false;
			}, 5000);
		},
	}
}
</script>