<template>
	<div class="training-profile__card" :class="['training-profile__card--'+theme]">
		<h3 class="training-profile__card__title">{{title}}</h3>
		<table class="training-profile__table">
			<thead v-if="theme.includes('general-team') || theme.includes('bonus-target') ">
				<tr>
					<td></td>
					<td>
						<div class="progress-bar__flex" style="font-size: 11px; font-style: italic">
							<span>
								Week's performance
							</span>
							<span style="text-align:right;">
								Month to date progress
							</span>
						</div>

					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in readableKeys" :key="index">

					<td class="training-profile__label">
						<span v-if="item.key.includes('Bonus')">
							{{item.value.trim()}}
						</span>
						<span v-else>
							{{item.key.trim()}}:
						</span>
					</td>
					<td v-if="title === 'iHASCO'" class="checkmark" :class="progressClass(item.key, item.value)">
						<svg v-if="item.value !== '100.00%'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z"/></svg>
						<svg v-if="item.value === '100.00%'" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.393 7.5l-5.643 5.784-2.644-2.506-1.856 1.858 4.5 4.364 7.5-7.643-1.857-1.857z"/></svg>
					</td>

					<td v-else-if="percentIndex(item.key) !== null"  class="training-profile__value" >
						<div class="progress-bar__flex">
							<span :class="progressClass(item.key, item.value)">
							<span v-if="item.key.includes('Bonus')">
								{{targetValue(item.key.trim())}}
							</span>
							<span v-else>
								{{item.value.trim()}}
							</span>
							</span>
							
							<profile-progress :radius="65" :progress="percentIndex(item.key)" :stroke="5" class="small" :class="progressResultClass(item.key)" />
						</div>
					</td>

					<td v-else class="training-profile__value" :class="progressClass(item.key, item.value)">
						<span v-if="item.key.includes('Bonus')">
							{{targetValue(item.key.trim())}}
						</span>
						<span v-else>
							{{item.value.trim()}}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
<!-- percentIndex(item.key, item.value) -->
</template>
<script>
import ProfileProgress from '@/components/TrainingComponents/ProfileProgress.vue';

export default {
	components: {
		ProfileProgress,
	},

	props: {
		keys: {
			type: Object,
			required: true,
		},

		title: {
			type: String,
			required: true
		},

		theme: {
			type: String,
			required: false
		},
		// profile: {
		// 	type : Object,
		// 	required: true
		// },
		list: {
			type:Boolean,
			required: false
		},
		fullwidth: {
			type:Boolean,
			required: false
		}
	},

	computed: {
		readableKeys () {
			let array = [];
			for (const [key, value] of Object.entries(this.keys)) {
				if(!key.includes('Percent') && !key.includes('Progress') && key.trim() !== 'TBC' && !key.trim().includes('Actuals') && key.trim() !== 'Widget Total %') {
					array.push({key: key, value: value});
				} 
			}

			return array;
		}
	},

	methods: {
		progressClass(idx, value) {
			let progressKey = this.keys[idx+ ' Progress'];
			if(progressKey) {

				if(progressKey.toLowerCase().includes('on')) {
					return 'on-track'
				}

				if(progressKey.toLowerCase().includes('off')) {
					return 'off-track'
				}

				if(progressKey.toLowerCase().includes('N/A')) {
					return 'na'
				}

			}

			if(this.title === 'iHASCO') {
				if(value === '100.00%') {
					return 'on-track'
				}
				return 'off-track';
			}

			return 'noprogresskey';
		},

		progressResultClass(idx) {

			let progressKey = this.keys[idx+ ' Percent Result'];
			if(progressKey) {
				return this.convertToSlug(progressKey);
			}

			return 'na';
		},

		percentIndex(idx) {
			let percKey = this.keys[idx+ ' Percent'];
			if(percKey) {
				return parseInt(percKey)
			}

			return null;
		},

		listLabel(item) {
			if(this.list) {
				return item.replace(/([a-zA-Z ])/g, "");
			}
			return item;
		},

		targetValue(key) {
			let targetKey = key.replace('Bonus ', '');
			return this.keys[targetKey + ' Actuals'];
		},

		convertToSlug(str)
		{
			return str
				.toLowerCase().trim().replace(/ /g,'-').replace(/[^\w-]+/g,'');
		}

	}
};
</script>
