<template>
	<div
		v-if="user && currentUserHasAccess"
		class="pirkx-dashboard"
	>
		<p>
			<a
				v-if="!user.pirkx_consent"
				class="pirkx-dashboard__button button button--pill button-alt"
				@click="register"
			>{{ layout.registration_button_text }}</a>

			<a
				v-if="!verifying && pirkx_auth"
				:href="'https://www.pirkx.com/verifyToken/' + pirkx_auth"
				class="pirkx-dashboard__button button button--pill button-alt"
				target="_blank"
			>{{ layout.button_text }}</a>
		</p>
		<p
			v-if="!verifying && user.pirkx_consent && !pirkx_auth"
			class="pirkx-dashboard__error"
		>We can't access your Pirkx Dashboard right now. Please contact a team member.</p>
		<p
			v-if="error"
			class="pirkx-dashboard__error"
		>{{ error }}</p>
		<p
			v-if="user.pirkx_consent !== true"
			class="pirkx-dashboard__terms"
		>{{ layout.pirkx_registration_terms }}</p>
		<p
			v-if="recently_consented"
			class="pirkx-dashboard__complete"
		>Your Pirkx registration is complete! You can use the above button to access your Pirkx dashboard at any time.</p>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			user: null,
			error: null,
			verifying: false,
			pirkx_auth: null,
			recently_consented: false,
		};
	},

	computed: {
		...mapState({
			currentUser: 'user',
		}),

		currentUserHasAccess () {
			if (!this.user) {
				return false;
			}

			if (!this.layout.restrict_access) {
				return true;
			}

			let userHasAccess = false;

			this.layout.restricted_access_users.forEach(user => {
				if (user.ID == this.user.id) { // eslint-disable-line
					userHasAccess = true;
				}
			});

			return userHasAccess;
		},
	},

	watch: {
		currentUser () {
			if (this.currentUser) {
				this.user = {...this.currentUser};
				this.verifyPirkxAction();
			} else {
				this.user = {};
			}
		},
	},

	mounted () {
		this.loadUser();
	},

	methods: {
		...mapActions(['loadUser']),

		register () {
			this.error = null;

			window.axios
				.post('wp-json/kxi/v1/pirkx-register')
				.then(res => {
					if (!res.data.success) {
						this.error = res.data.message;
					} else {
						this.pirkx_auth = res.data.auth_token;
						this.user.pirkx_consent = true;
						this.recently_consented = true;
					}
				}).catch((e) => {
					console.error(e);
					this.error = e.message;
				});
		},

		verifyPirkxAction () {
			this.verifying = true;

			if (!this.user.pirkx_consent) {
				this.verifying = false;
				return;
			}

			if (!this.user.pirkx_user_id) {
				this.error = 'We can\'t access your Pirkx Dashboard right now. Please contact a team member.';
				this.verifying = false;
				return;
			}

			window.axios
				.post('wp-json/kxi/v1/pirkx-refresh')
				.then(res => {
					if (!res.data.success) {
						this.error = res.data.message;
					} else {
						this.pirkx_auth = res.data.auth_token;
					}

					this.verifying = false;
				}).catch((e) => {
					console.error(e);
					this.error = e.message;

					this.verifying = false;
				});
		},
	},
};
</script>
