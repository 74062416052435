<template>
	<span
		class="error form__error">
        
	</span>
</template>

<template>
	<article class="article container">
		<section class="layout layout--section_header">
			<section-header
				:layout="{ theme: ['red-blue'],  hide_title: false }"
				:title="{ rendered: 'Access Denied' }"
			/>
		</section>

		<section class="layout layout--mixed_content">
			<mixed-content :layout="{ mixed_content: '<p>Unfortunately, it looks like you do not have permission to access to this page. Please contact the site administrator for more information or if you believe you should have access to this page...</p>' }" />
		</section>
	</article>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';

export default {
	name: 'KXiForbidden',

	components: {
		SectionHeader,
		MixedContent,
	},
};
</script>
