<template>
	<article
		v-if="ready"
		class="article container"
	>
		<section
			v-for="(layout, index) in page.acf.flexible_content"
			:key="index"
			:class="'layout layout--' + layout.acf_fc_layout"
		>
			<section-header
				v-if="layout.acf_fc_layout == 'section_header'"
				:layout="layout"
			/>

			<mixed-content
				v-if="layout.acf_fc_layout == 'mixed_content'"
				:layout="layout"
			/>

			<mixed-content-carousel
				v-if="layout.acf_fc_layout == 'mixed_content_carousel'"
				:layout="layout"
			/>

			<mixed-content-grid
				v-if="layout.acf_fc_layout == 'mixed_content_grid'"
				:layout="layout"
			/>
		</section>
	</article>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';
import MixedContentCarousel from '@/components/ArticleComponents/MixedContentCarouselComponent';
import MixedContentGrid from '@/components/ArticleComponents/MixedContentGridComponent';

export default {
	name: 'PageNotFound',

	components: {
		SectionHeader,
		MixedContent,
		MixedContentCarousel,
		MixedContentGrid,
	},

	data () {
		return {
			page: null,
			ready: false,
		};
	},

	created () {
		this.get_page();
	},

	methods: {
		get_current_slug () {
			return 'oops';
		},

		get_page () {
			window.axios.get('wp-json/wp/v2/pages?_embed&slug=' + this.get_current_slug())
				.then(res => {
					if (res.status !== 200) {
						// Show some error or 404 page
						this.$router.replace({name: 'error'});
					}

					this.page = [...res.data][0];

					this.ready = this.page.acf;
				}).catch(err => {
					// Show some error or 404 page
					console.log(err);
					this.$router.replace({name: 'error'});
				});
		},
	},
};
</script>
