<template>
	<div>
		<img
			:src="image"
			class="full-width-image"
		>
	</div>
</template>

<script>
export default {
	props: {
		image: {
			type: String,
			required: true,
		},
	},
};
</script>
