<template>
	<div class="achievement__wrap" :class="{'complete' : isComplete}" v-if="achievements">
		<span class="blip"></span>
		<span class="blip"></span>
		<span class="blip"></span>
		<span class="blip blip--h"></span>
		<span class="blip blip--h"></span>
		<span class="blip blip--h"></span>
		<h2 class="achievement__title">Samsung Week Bingo</h2>
		<div class="achievement__grid">
			<div class="achievement" v-for="(i, ach) in achievements" :key="ach">
				<div class="achievement__content" :class="'fulfilled' + i">
					<img :src="nameToSlug(ach, i)" :title="ach" :alt="ach"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: {
		achievements: {
			required: true
		},
	},

	data() {
		return {

		}
	},

	mounted() {
	},

	computed: {
		isComplete() {
			
			let items = Object.entries(this.achievements);
			items.pop();

			let toComplete = items.length;
			let completedItems = 0;
			for (const [key, value] of items) {
				if(key === 'Z fold sale' || key === 'Bespoke') {
					return;
				}

				if(value == 1 ){
					completedItems++;
				}
			}
			return completedItems === toComplete;
		}
	},
	
	methods: {
		nameToSlug(text, complete) {
			
			let slug = text
						.toLowerCase()
						.replace(/ /g, '-')
						.replace(/[^\w-]+/g, '');

				if(complete == 1) {
					slug += '-active'
				}
				return '/static/img/achievements/' + slug +'.png';
			}
	}
};
</script>
