<template>
	<div style="width: 100%;">
		<div
			ref="textareaCover"
			class="textarea-cover"
			v-html="commentMention"
		/>
		<Mentionable
			:keys="['@']"
			:items="items"
			filtering-disabled
			insert-space
			omit-key
			@open="searchUsers()"
			@search="searchUsers($event)"
			@apply="addMentions"
		>
			<textarea
				ref="comment"
				v-model="content"
				type="text"
				:placeholder="placeholder"
				class="comment__compose__input"
				:id="'comment-input-'+postId"
				:class="extraClass"
				:rows="rows"
				@keyup="commentScroll"
				@scroll="commentScroll"/>

			<template #no-result>
				<div class="comment__compose__input__noresult">
				{{ loading ? 'Loading...' : 'No users found' }}
				</div>
			</template>
		</Mentionable>
	</div>
</template>

<script>
import { Mentionable } from 'vue-mention';
import { mapActions, mapMutations, mapState } from 'vuex';

Mentionable.methods.onKeyDown = function (e) {
	if (this.key) {
		if (e.key === 'ArrowDown' || e.keyCode === 40) {
			this.selectedIndex++;
			if (this.selectedIndex >= this.displayedItems.length) {
				this.selectedIndex = 0;
			}
			this.cancelEvent(e);
		}
		if (e.key === 'ArrowUp' || e.keyCode === 38) {
			this.selectedIndex--;
			if (this.selectedIndex < 0) {
				this.selectedIndex = this.displayedItems.length - 1;
			}
			this.cancelEvent(e);
		}
		if (
			(e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
		) {
			this.applyMention(this.selectedIndex);
			this.cancelEvent(e);
		}
		if (e.key === 'Escape' || e.keyCode === 27) {
			this.closeMenu();
			this.cancelEvent(e);
	  this.items = [];
		}
	}
};

export default {
	components: {
    	Mentionable,
	},
	data () {
		return {
			content:"",
			items: [],
			loading: true,
			allUsers: [],
		}
	},

	props: {
		placeholder: {
			default: 'Leave a comment...',
		},
		extraClass: {
			default: ''
		},
		rows: {
			default: 1
		},
		postId: {
			default: 0
		},
	},

	watch: {
		content (after, before) {
			this.$emit('update', after);
		},
	},

	mounted () {
		setTimeout(() => {
			this.resize();
			window.addEventListener('resize', this.resize);
			this.$once('hook:beforeDestroy', () => {
				window.removeEventListener('resize', this.resize);
			});
		});

		this.loadUsers();
	},

	computed: {
		commentMention () {
			if (!this.content) {
				return;
			}	
			const replaced =
				this.content
					.replaceAll('&', '&amp;')
					.replaceAll('>', '&gt;')
					.replaceAll('<', '&lt;') + '\n\n';
			const search = new RegExp(
				this.allUsers
					.slice()
					.sort((a, b) => b.value.length - a.value.length)
					.map((user) => user.value)
					.join('|'),
				'g'
			);

			return replaced.replace(search, (match, offset) => {
				if (match) {
					return `<span class="mention-str">${match}</span>`;
				}
			});
		},
	},
	methods: {
		...mapActions([
			'loadUsers',
		]),

		reset() {
			this.content = "";
		},

		searchUsers (searchText = '') {
			this.allUsers = this.$store.getters.filteredUsers();
			this.items = this.$store.getters.filteredUsers(searchText);
    	},

		addMentions (item, key, replacedWith) {
		},

		resize () {
			const barWidth = this.$refs.comment.getBoundingClientRect().width - this.$refs.comment.clientWidth - 2; // border
			this.$refs.textareaCover.style.paddingRight = `calc(12px + ${barWidth}px)`;
		},

		commentScroll () {
			this.$refs.textareaCover.scrollTop = this.$refs.comment.scrollTop;

			this.resize();
		},
	}
}
</script>