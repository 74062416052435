export default {
	index (user_id) {
		return window.axios.get('wp-json/kxi/v1/training-sessions/index?user_id='+user_id);
	},

	show (show) {
		return window.axios.get('wp-json/kxi/v1/training-sessions/show/?session='+show);
	},

	submit (form) {
		return window.axios.post('wp-json/kxi/v1/training-sessions/submit', form);
	},

	delete (id) {
		return window.axios.post('wp-json/kxi/v1/training-sessions/delete', {id: id});
	},

	submitFeedback(sessionId, fields) {
		return window.axios.post('wp-json/kxi/v1/training-sessions/feedback/', {'fields': fields, 'session_id' : sessionId });
	},

	updateOrder (params) {
		return window.axios.post('wp-json/kxi/v1/training-sessions/update-order', params);
	},
};
