export default {
	getSchedules () {
		return window.axios.get('wp-json/kxi/v1/schedule');
	},

	createLeaveRequest (data) {
		return window.axios.post('wp-json/kxi/v1/leave-requests', data);
	},

	getLeaveRequests (params) {
		return window.axios.get('wp-json/kxi/v1/leave-requests', params);
	},

	getScheduleStaff (params) {
		return window.axios.get('wp-json/kxi/v1/schedule/staff', {params: params});
	},
};
