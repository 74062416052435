<template>
	<div>
		<router-link
			v-if="!event"
			:to="{path: path}"
			class="app-nav__link ripple"
			@click.native="navigateOrScroll"
		>
			<img
				:src="icon"
				:alt="title"
				class="app-nav__icon"
			>
			<span
				v-if="active"
				class="app-nav__link-active"
			>&nbsp;</span>
		</router-link>
		<span
			v-if="event"
			class="app-nav__link ripple"
			@click.prevent="broadcastEvent(event)"
		>
			<img
				:src="icon"
				:alt="title"
				class="app-nav__icon"
			>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			required: true,
			type: String,
		},
		path: {
			required: false,
			type: String,
			default: null,
		},
		title: {
			required: true,
			type: String,
		},
		active: {
			type: Boolean,
			required: false,
			default: false,
		},
		event: {
			type: String,
			required: false,
			default: null,
		},
	},

	computed: {
		icon () {
			return require('../svg/' + this.name + '.svg');
		},
	},

	methods: {
		broadcastEvent (eventName) {
			this.$root.$emit(eventName);
		},
		navigateOrScroll() {
			if(this.active) {
					this.$root.$emit('navScrollTop');
				  window.scrollTo({top: 0, behavior: 'smooth'});
			}
		},
	},
};
</script>
