<template>
<span>
	<span class="user-badge " :class="'user-badge--'+rank" v-if="rank && !avatar" v-tooltip="rank + ' Service Badge'" :style="'border-color:' + color">
		<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.29695 0H7.70306V6.1857H8.29695V0Z" fill="currentColour"/>
			<path d="M0.463767 4.17916L0.166992 4.69318L5.52391 7.78608L5.82068 7.27207L0.463767 4.17916Z" fill="currentColour"/>
			<path d="M5.52415 9.96059L0.167236 13.0535L0.464011 13.5675L5.82092 10.4746L5.52415 9.96059Z" fill="currentColour"/>
			<path d="M8.2968 11.5601H7.70325V17.7458H8.2968V11.5601Z" fill="currentColour"/>
			<path d="M10.4759 9.95897L10.1791 10.4731L15.5361 13.566L15.8329 13.0519L10.4759 9.95897Z" fill="currentColour"/>
			<path d="M15.5363 4.17959L10.1793 7.27255L10.4762 7.78666L15.8332 4.6937L15.5363 4.17959Z" fill="currentColour"/>
		</svg>
		<i class="user-badge__tooltip" ></i>
	</span>
	<img :src="avatar" v-if="avatar" class="user-badge avatar-badge" :class="'avatar-badge--'+rank" v-tooltip="rank + ' Service Badge'"  :style="'border-color:' + color"/>
</span>
</template>
<script>
export default {
	props: {
		rank: {
			required: false,
			type: String,
		},
		avatar: {
			required: false,
			Type: String
		},
		color: {
			Type: String,
			default: '#fff',
			required: false,
		}
	},
}
</script>