<template>
	<div>
		<div v-if="formSubmitted">
			<header class="feedback-form__header">
				<h3 class="feedback-form__title">You correctly answered {{result.correct.length}}/{{result.question_count}} </h3>
				<div v-if="result.incorrect.length > 0" v-html="session.incorrect_answers" style="line-height: 24px;">
					
				</div>
				<div v-if="result.incorrect.length === 0"  v-html="session.all_correct" style="line-height: 24px;">

				</div>
				<div v-if="result.incorrect.length" style="display:none">
					<p style="margin-bottom: 5px;">You incorrectly answered the following questions:</p>
					<p v-for="(incorrect, i) in result.incorrect" :key="i" style="margin-bottom: 5px;"  class="feedback-form__description">
						{{incorrect.label}}
					</p>
				</div>
			</header>
		</div>

		<form
			v-else
			action="#"
			class="feedback-form"
			@submit.prevent="submit()"
		>
			<div
				v-for="(field, field_index) in form.fields"
				:key="field_index"
			>
				<div v-if="field.visibility !== 'hidden' && conditionalLogicCheck(field)">
					<div v-if="field.type === 'text' || field.type === 'email' || field.type === 'password'">
						<fieldset>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<input
								:name="field.id"
								:id="field.id"
								:type="field.type"
								:required="field.isRequired"
								v-model="fields[field.id]"
								class="feedback-form__text"
							>
						</fieldset>
					</div>

					<div v-if="field.type === 'select' || field.type === 'multiselect'">
						<fieldset v-if="fields[field.id]">
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<select
								:name="field.id"
								:id="field.id"
								:required="field.isRequired"
								:multiple="field.type === 'multiselect'"
								v-model="fields[field.id]"
								class="feedback-form__select"
							>
								<option
									v-for="(choice, index) in field.choices"
									:key="index"
									:value="choice.value"
								>
									{{ choice.value }}
								</option>
							</select>
						</fieldset>
					</div>

					<div v-if="field.type === 'checkbox'">
						<fieldset>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<div
								v-for="(input, index) in field.inputs"
								:key="index"
								class="feedback-form__checkbox-wrap"
							>
								<label
									:for="get_input_id(field, index)"
									class="feedback-form__checkbox-label"
									v-text="input.label"
								/>

								<input
									:id="get_input_id(field, index)"
									:name="field.id + '[]'"
									v-model="fields[input.id]"
									:value="field.choices[index].value"
									:true-value="field.choices[index].value"
									class="feedback-form__checkbox"
									type="checkbox"
									:disabled="field.disabled"
								>
							</div>
						</fieldset>
					</div>
					<div v-if="field.type === 'radio'">
						<fieldset>
							<legend class="feedback-form__legend">
								{{ field.label }}
								<span
									v-if="field.isRequired"
									class="required"
								>*</span>
							</legend>

							<div
								v-for="(input, index) in field.choices"
								:key="index"
								class="feedback-form__checkbox-wrap"
							>
								<label
									:for="get_input_id(field, index)"
									class="feedback-form__checkbox-label"
									v-text="input.text"
								/>

								<input
									:id="get_input_id(field, index)"
									:name="'radio'+field.id"
									v-model="fields[field.id]"
									:value="field.choices[index].value"
									:true-value="field.choices[index].value"
									class="feedback-form__checkbox"
									type="radio"
									style="border-radius: 15px;"
								>
							</div>
						</fieldset>
					</div>

					<div
						v-if="field.type === 'textarea'"
						class="feedback-form__textarea-wrap"
					>
						<label
							:for="field.id"
							class="feedback-form__textarea-label"
						>
							{{ field.label }}
							<span
								v-if="field.isRequired"
								class="required"
							>*</span>
							<span v-if="field.id !== 11" style="float:right">{{fields[field.id] ? fields[field.id].trim().length  : 0}}/50</span>
						</label>
						<textarea
							:name="field.id"
							:id="field.id"
							v-model="fields[field.id]"
							:required="field.isRequired"
							:minlength="field.id !== 11 ? 50 : 0"
							class="feedback-form__textarea"
							cols="30"
							rows="5"
						/>
					</div>

					<div
						v-if="field.type === 'hidden'"
						class="feedback-form__hidden-wrap"
					>
						<input
							:name="field.id"
							:id="field.id"
							v-model="fields[field.id]"
							type="hidden"
						>
					</div>
				</div>
			</div>


			<error-component
				v-if="errorMessage"
				:text="errorMessage"
			/>

			<div style="margin: 1rem 0;">
				<button
					type="submit"
					class="button button--pill"
					:disabled="submitting">
						Submit Feedback
					<svg width="24" height="24" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#000" v-if="submitting" style="margin-bottom: -5px"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /> <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /> </circle> <circle cx="22" cy="22" r="1"> <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /> <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /> </circle> </g> </svg>
				</button>
			</div>

		</form>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ErrorComponent from '@/components/ErrorComponent';
import CookieService from '@/services/CookiesService';
import TrainingSessionsService from '@/services/TrainingSessionsService';
import {mapState} from 'vuex';

export default {

	components: {
		ErrorComponent,
	},

	props: {
		form: {
			type: Object,
			required: true,
		},

		session: {
			type: Object,
			required: true,
		},
	},

	data: function () {
		return {
			fields: {},
			errorMessage: '',
			formSubmitted: false,
			submitting: false,
			result: {
				correct: [],
				incorrect: [],
				question_count: 0,
			}
		};
	},

	computed: {
		...mapState(['user']),
	},

	validations () {
		let fields = {};

		this.form.fields.forEach(function (field) {
			fields[field.id] = {}
			if (field.isRequired === true) {
				fields[field.id] = {
					required,
				};
			}
		});

		return {
			fields,
		};
	},

	watch: {
		form () {
			this.initFields();
		},
	},

	mounted () {
		if (CookieService.get('formsubmitted_' + this.form.id)) {
			this.formSubmitted = true;
		}

		this.initFields();
	},

	methods: {
		conditionalLogicCheck(field) {
			if(typeof field.conditionalLogic === 'object') {
				let show = false;
				field.conditionalLogic.rules.forEach(rule => {
					if(rule.operator === 'is') {
						if(this.fields[rule.fieldId] === rule.value) {
							show = true;
						}
					}

					if(rule.operator === 'contains') {
						if(this.fields[rule.fieldId] && this.fields[rule.fieldId].includes(rule.value)) {
							show = true;
						}
					}
				});

				return show;
			}

			return true;
		},

		get_input_id (field, input = null) {
			let id = 'field-' + field.id;
			if (input !== null) {
				id += '-' + input;
			}

			return id;
		},

		initFields () {
			const fields = {};
			this.form.fields.forEach(field => {
				fields[field.id] = '';
				if (field.type === 'checkbox' || field.type === 'multiselect') {
					fields[field.id] = [];
				}

				if (field.type === 'radio' ){
					fields[field.id] = "";

				}
				if (field.label.toLowerCase().includes('team id')) {
					fields[field.id] = this.user.team_id;
				}

				if (field.label.toLowerCase() === 'name') {
					fields[field.id] = this.user.name;
				}

				if (field.label.toLowerCase() === 'name of training session') {
					fields[field.id] = this.session.title
				}
				if (field.adminLabel === 'Areas Covered') {
					field.disabled = true;
					field.choices.forEach((choice, idx) => {
						let prePopped = this.session.areas_covered.find((area) => {
							return area === choice.value
						});

						if(prePopped) {
							fields[field.id+'.'+(idx+1)] = choice.value;
						}
					});

					
				}

				if (typeof this.$route.query.feedback_result !== 'undefined') {
					if (field.label.toLowerCase() === 'how are you feeling?') {
						fields[field.id] = this.$route.query.feedback_result;
					}
				}
			});

			this.fields = fields;
			this.$forceUpdate();
		},

		submit () {
			if(this.submitting) {
				return;
			}

			this.submitting = true;
			this.errorMessage = '';
				
			TrainingSessionsService.submitFeedback(this.session.id, this.fields)
				.then(res => {
					this.result = res.data;
					this.submitting = false;
					this.formSubmitted = true;
					var expire = new Date();
					expire.setHours(23, 59, 59, 0);
					CookieService.set('formsubmitted_' + this.form.id + '_'+ this.session.id, true, expire);
				}).catch(() => {
					this.submitting = false;
					this.formSubmitted = false;
					this.errorMessage = 'Sorry, there has been a problem saving your feedback';
				});
		},
	},
};
</script>
