import NotificationsService from '@/services/NotificationsService';
import AuthService from '@/services/AuthService';

export default {
	namespaced: true,
	state: () => {
		return {
			all: [],
			loaded: false,
		};
	},
	actions: {
		loadNotifications ({commit}) {
			if (!AuthService.isLoggedIn()) {
				return;
			}
			NotificationsService.index().then(res => {
				commit('setNotifications', res.data);
				commit('setLoaded');
			});
		},
		markAsRead ({dispatch}, notificationIds) {
			NotificationsService.markAsRead(notificationIds).then(() => {
				dispatch('loadNotifications');
			});
		},
	},
	mutations: {
		setNotifications (state, notifications) {
			state.all = notifications;
		},
		setLoaded (state, loaded = true) {
			state.loaded = loaded;
		},
	},
	getters: {
		unreadNotifications: state => state.all.filter(n => !n.is_read),
		hasUnread: state => state.all.filter(n => !n.is_read).length > 0,
	},
};
