/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
	  setTimeout(function () {
		window.dispatchEvent(new CustomEvent('sw:updated'));
		}, 1000);
    },
    updated () {
		console.log('updated')
	},
	offline () {
		console.log('error')
		window.dispatchEvent(new CustomEvent('offline'));
	},
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
