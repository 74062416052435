
<template>
	<div class="container training-sessions">
		<div class="training-sessions__loading" v-if="loading">
			<svg width="100" height="100" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff" v-if="loading">
				<g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
						<path d="M36 18c0-9.94-8.06-18-18-18">
							<animateTransform attributeName="transform"	type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
						</path>
					</g>
				</g>
			</svg>
		</div>
		<h2 class="training-sessions__title">New Training Session</h2>
		<form @submit.prevent="submit">
			<fieldset>
				<label class="form__label">Session Name</label>
				<input type="text" required="required" name="title" v-model="form.title" 	class="form__control form__control--bordered" />
			</fieldset>

			<fieldset>
				<label class="form__label">Session Date</label>
				<input type="date" required="required" name="session_date" v-model="form.session_date" 	class="form__control form__control--bordered"/>
			</fieldset>

			<fieldset>
				<label class="form__label">Select Attendees</label>
				<v-select label="name" multiple :options="attendees"  v-model="form.attendees" class="form__control form__control--bordered" style="color: #000"></v-select>
			</fieldset>
			
			<fieldset>
				<label class="form__label">Select Trainers</label>
				<v-select label="name" multiple :options="trainers"  v-model="form.trainers" class="form__control form__control--bordered"></v-select>
			</fieldset>

			<fieldset v-if="externalTrainer">
				<label class="form__label">External Trainer name</label>
				<input type="text" required="" name="external-trainer" v-model="form.external_trainers"	class="form__control form__control--bordered" />
			</fieldset>

			<fieldset>
				<label class="form__label">What area was covered in today's training *</label>
				<v-select label="name" multiple :options="areas_covered"  v-model="form.areas_covered" class="form__control form__control--bordered"></v-select>
			</fieldset>

			<div class="ts">
				<h3 class="training-sessions__title training-sessions__title--small">Attendee Questions</h3>
				<p class="training-sessions__intro">Create some questions specific to this training session for your attendees to answer</p>
				<div class="ts__question" v-for="(q,i) in form.questions" :key="i">
					<span class="ts__question__no">Question #{{ i + 1 }} 
						<span @click="form.questions.splice(i,1)"><svg xmlns="http://www.w3.org/2000/svg" style="height: 20px; width: 20px; margin-bottom: -4px; margin-left: 5px; color: #F96F6F;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
						</span>
					</span>
					<fieldset>
						<label class="form__label">Question Label</label>
						<input type="text" required="required" name="label" v-model="q.label" 	class="form__control form__control--bordered" />
					</fieldset>

					<fieldset class="form__select">
						<label class="form__label">Question Type</label>
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
						<select name="type" v-model="q.type"  required="required" class="form__control form__control--bordered">
							<option value="text">Text</option>
							<option value="radio">Multiple Choice</option>
							<option value="select">Select Dropwdown</option>
						</select>
					</fieldset>
					<fieldset class="form__select" v-if="q.type === 'text' && 1 == 0">
						<label class="form__label">Answer Minimum Length</label>
						<input type="number" required="required" v-model="q.length" class="form__control form__control--bordered" />
					</fieldset>
					<div v-if="q.type !== 'text'">
						<div class="ts__question_choices">
							<label class="form__label" style="text-decoration:underline;">Options</label>
							
							<div v-for="(choice, ci) in q.choices" :key="ci" class="ts__question__choices__choice">
								<input type="text" name="choice" required="required" v-model="choice.value" class="form__control form__control--bordered" />
								<svg @click="q.choices.splice(ci,1)" width="24" v-if="q.choices.length > 1" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 12H9H15ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#F96F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</div>

							<button class="text-button" type="button" @click="addChoice(q)">Add Option
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 9V12V9ZM12 12V15V12ZM12 12H15H12ZM12 12H9H12ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#98F7BB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</button>
						</div>

						<fieldset class="form__select" >
							<label class="form__label">Correct Answer <span style="font-size: 11px;">(ensure exactly same as option above, leave field blank to not be considered a question)</span></label>
							<input type="text" v-model="q.correct_answer" class="form__control form__control--bordered" />
						</fieldset>
					</div>
				</div>
				<button style="margin: 1rem 0 1rem auto;" class="text-button" type="button" @click="addQuestion">Add a Question <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12 9V12V9ZM12 12V15V12ZM12 12H15H12ZM12 12H9H12ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#98F7BB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</button>
				<fieldset v-if="form.questions.length > 0">
					<label class="form__label">Incorrectly Answered Questions Message</label>
					<editor
						:api-key="tinyApi"
						v-model="form.incorrect_answers"
						:init="{
							height: 200,
							menubar: false,
							plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
							],
							toolbar:
							'undo redo | formatselect | bold italic backcolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent | removeformat | help'
						}"/>
				</fieldset>

				<fieldset style="margin: 10px 0 20px;" v-if="form.questions.length > 0">
					<label class="form__label">Correctly Answered All Questions Message</label>
					<editor
						:api-key="tinyApi"
						v-model="form.all_correct"
						:init="{
							height: 200,
							menubar: false,
							plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
							],
							toolbar:
							'undo redo | formatselect | bold italic backcolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent | removeformat | help'
						}"/>
				</fieldset>
			</div>

			<div style="display: flex; margin-bottom: 15px;">
				<label class="form__label" style="margin: 0; margin-right: 5px;" for="sendNotifications">Send Notifications to Attendees</label>
				<input type="checkbox" id="sendNotifications" name="sendNotifications" v-model="form.send_notifications"/>
			</div>

			<button class="button button--pill" :disabled="loading" type="submit">
				Save Training Session
				<svg width="18" height="18" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#333" v-if="loading"  style="position: relative; margin-left: 5px; top: 3px;">
					<g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
							<path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform attributeName="transform"	type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
							</path>
						</g>
					</g>
				</svg>
			</button>
		</form>
	</div>
</template>
<script>

import TrainingSessionsService from '@/services/TrainingSessionsService';
import Editor from '@tinymce/tinymce-vue'
import { required,  minLength } from 'vuelidate/lib/validators';
export default {
	components: {
		'editor': Editor
	},
	data() {
		return {
			loading: true,
			tinyApi: 'n4k2l8d6e5dnosy9468umm6ivw1jmwjr73shwfeb2ffdjplg',
			form: {
				title: '',
				user_id: null,
				session_date: null,
				attendees: [],
				questions: [],
				trainers: [],
				areas_covered: [],
				incorrect_answers: "You didn't quite get 100% on the quiz. The training team will catch up with you on any questions you didn't get correct; so you can brush up on your knowledge. In the meantime, please check out some further reading here:",
				all_correct: "Well done! You scored 100% in the quiz. What a stellar effort!"
			},
			users: [],
			allUsers: [],
			areas_covered: [
				'IM',
				'DA',
				'TV',
				'Samsung Services',
				'KX Service Philosophy',
				'Best Practices',
				'Other',
				'Huddle Training',
			],
		};
	},

	validations: {
		form: {
			title: {
				required,
				minLength: minLength(1),
			},
			questions: {
				$each: {
					type: { required },
					label: {required: required,}
				}
			}
		},
	},
	mounted() {
		// let user = JSON.parse(window.localStorage.getItem('user'));

			//   if(user.role !== 'administrator' && user.role !== 'trainer') {
			// 	  window.location.href = '/';
			//   }

		window.axios.get('wp-json/wp/v2/users?per_page=100').then(res => {
			this.users = res.data;
			this.allUsers = res.data;
		});

		if(this.sessionId == this.$route.params.id) {
			this.loadTrainingSession();
		} else {
			this.loading = false;
		}
	},
	methods: {
		initialUsers() {
			window.axios.get('wp-json/wp/v2/users?per_page=100').then(res => {
				this.users = res.data;
			});
		},
		
		loadTrainingSession() {
			TrainingSessionsService.show(this.sessionId).then(res => {
				this.form = res.data;
				this.loading = false;
			});
		},

		addQuestion() {
			this.form.questions.push({
				type: 'text',
				length: 0,
				label: '',
				choices: [],
			})
		},

		addChoice(q) {
			q.choices.push({
				value: '',
			});
		},

		submit () {
			this.loading = true;
			this.errorMessage = '';
			this.successMessage = '';

			if (this.$v.$invalid === true) {
				this.errorMessage = 'Please fill out all fields.';
				this.loading = false;
				return;
			}

			TrainingSessionsService.submit(this.form)
			.then(res => {
				this.loading = false;
				this.successMessage = 'Thank You. Your message has been sent';
				if(this.sessionId) {
					window.location.replace('/training-sessions?saved='+this.sessionId);
				} else {
					window.location.replace('/training-sessions?new='+res.data.data.id);
				}
			}).catch(() => {
				this.loading = false;
				this.errorMessage = 'Sorry, there has been a problem saving your feedback';
			});
		},
	},
	computed: {
		trainers() {
			let trainers = this.allUsers.filter(user => {
				let roles = user.roles
				return roles.indexOf('trainer') > -1;
			}).map( user => {
				return {name: user.name, id: user.id}
			});

			trainers.push({name: 'External Trainer', id: null})

			return trainers;
		},

		externalTrainer( ) {
			if(!this.form.trainers) {
				return false;
			}

			var index = this.form.trainers.findIndex(p => p.name == "External Trainer");

			if(index > -1) {
				return true;
			}

			return false;
		},

		attendees() {
			return this.allUsers.map( user => {
				return {name: user.name, id: user.id}
			});
		}
	}
	};
</script>
<style  lang="scss">
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.vs--searchable {
	padding: .2rem;
	color: #fff;
}
.vs__open-indicator  {
	fill: #fff;
}
.vs__search {
	color: #fff;
}


.vs__search select {
	color: #000;
}
</style>