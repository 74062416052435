<template>
	<span
		v-if="error"
		class="error form__error">
		{{ error }}
	</span>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'ErrorComponent',
	props: {
		'name': {
			required: false,
			type: String,
			default: null,
		},
		'text': {
			required: false,
			type: String,
			default: null,
		},
		'index': {
			required: false,
			type: Number,
			default: null,
		},
	},

	computed: {
		...mapState(['errors']),

		error: function () {
			if (this.text) {
				return this.text;
			}
			let name = this.name;

			if (this.index !== null) {
				name = this.index + '.' + this.name;
			}

			if (this.errors[name]) {
				return this.errors[name][0];
			}

			return null;
		},
	},
};
</script>
