<template>
	<div class="search container">
		<button
			class="search__close ripple"
			title="Close Search"
			@click="close"
		>
			<img src="@/svg/close.svg">
		</button>

		<div class="search__form form">
			<h3 class="search__form-intro">Search StellarKX...</h3>

			<input
				v-debounce:500ms="logSearch"
				ref="kxiSearchBox"
				v-model="search"
				type="text"
				placeholder="Sickness policy, S22, Contact..."
				class="form__control search__input"
			>
		</div>

		<div
			v-if="search"
			class="search__results">
			<p
				v-if="!results.length"
				class="search__result search__result--no-results">
				<a>No results found.</a>
			</p>

			<div
				v-for="(result, index) in results"
				:key="index"
				class="search__result ripple"
			>
				<a
					v-if="result.type === 'search_item' && shouldOpenNewTab(result.link)"
					:href="result.link"
					target="_blank"
				>
					{{ result.title }}
				</a>

				<router-link
					v-else-if="result.type === 'search_item'"
					:to="{path: result.link}"
				>
					{{ result.title }}
				</router-link>

				<router-link
					v-else-if="result.type === 'post'"
					:to="{name: 'article', params: {slug: result.link}}"
				>
					{{ result.title }}
				</router-link>

				<router-link
					v-else-if="result.type === 'page'"
					:to="{path:result.link}"
				>
					{{ result.title }}
				</router-link>

				<router-link
					v-else-if="result.type === 'user'"
					:to="{ name: 'usersprofile', params: { id: result.id }}" 
				>
					{{ result.title }}
				</router-link>
			</div>
		</div>
		<div
			v-if="search && results.length"
			class="search__results-gradient"
		/>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import LogService from '@/services/LogService';

export default {
	data () {
		return {
			search: '',
		};
	},

	computed: {
		...mapState([
			'searchOpen',
		]),
		results () {
			return this.$store.getters.filteredSearchItems(this.search);
		},
	},

	watch: {
		searchOpen () {
			if (this.searchOpen && !this.$route.query.search) {
				this.$router.push({
					query: {
						...this.$router.query,
						search: 1,
					},
				});
			} else if (!this.searchOpen && this.$route.query.search) {
				this.$router.push({
					query: {
						...this.$router.query,
						search: null,
					},
				});
			}
		},

		$route (newRoute, oldRoute) {
			this.search = '';
			if (newRoute.query['search'] && !this.searchOpen) {
				this.openSearch();
			} else if (!newRoute.query['search'] && this.searchOpen) {
				this.closeSearch();
			}
		},
	},

	mounted () {
		this.loadSearchItems();

		this.$root.$on('openSearch', () => {
			this.$refs.kxiSearchBox.focus();
		});
	},

	methods: {
		...mapActions([
			'loadSearchItems',
		]),

		...mapMutations([
			'closeSearch',
			'openSearch',
		]),

		shouldOpenNewTab(url) {
			if(url.includes('http') && !url.includes(process.env.BASE_URL) && !url.includes('app-stage.stellarkx.com') && !url.includes('stellarkx.com')) {
				return true;
			}
			return false;
		},

		close () {
			this.search = '';
			this.closeSearch();
			this.$emit('close');
		},

		logSearch () {
			if (this.search !== '') {
				LogService.saveSearchLog(this.search, this.results.length);
			}
		},
	},
};
</script>
