<template>
	<div class="custom-menu">
		<ul class="custom-menu__list">
			<li
				v-for="(item, index) in menuItemsForRole"
				:key="index"
				:style="'background-image: url(' + item.icon + ');'"
				class="custom-menu__item"
			>
				<a
					:href="get_child_permalink(item)"
					class="custom-menu__link"
					v-html="item.name"
				/>
				<ul
					v-if="item.children.length"
					class="custom-menu__list custom-menu__sub-list"
				>
					<li
						v-for="(child, index) in item.children"
						:key="index"
						class="custom-menu__item custom-menu__sub-item"
					>
						<a
							:href="get_child_permalink(child)"
							class="custom-menu__link custom-menu__sub-link"
							v-html="child.name"
						/>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		menu: {
			type: Array,
			required: true,
		},
	},

	data(){
		return {
			user: null,
		}
	},

	mounted() {
		this.user = JSON.parse(window.localStorage.getItem('user'));
	},

	methods: {
		get_child_permalink (item) {
			if (item.classes.indexOf('download') >= 0) {
				return item.link;
			}

			let link = document.createElement('a');
			link.href = item.link;
			return link.pathname;
		},
	},

	computed: {
		menuItemsForRole() {
			return this.menu.filter((item) => {
				if(!item.access_restriction || item.access_restriction === null || item.access_restriction === 'Subscriber') {
					return true;
				}

				if(typeof this.user !== 'undefined' && this.user) {
					if( this.user.role.toLowerCase() === item.access_restriction.toLowerCase() || this.user.role === 'administrator' ) {
						return true
					}

					// If restricted to contributor and user is a trainer, can view
					if(  item.access_restriction.toLowerCase === 'contributor' && this.user.role.toLowerCase() === 'trainer') {
						return true
					}
					
				}
				return false;
			});
		}
	}
};
</script>
