<template>
	<div id="app">
		<NavigationSidebar :menuOpen="menuOpen"/>
		<app-header
			v-if="isLoggedIn && !searchOpen"
		/>
		<router-view />
		<app-nav v-if="isLoggedIn" />
		<app-search :class="{show: searchOpen}" />
		<notification />
		<post-update :user="user"  v-if="isLoggedIn"/>
	</div>
</template>

<script>
import AppSearch from '@/components/SearchComponent';
import PostUpdate from '@/components/TimelineComponents/PostUpdateComponent';
import Notification from '@/components/Notification';
import NavigationSidebar from '@/components/NavigationsidebarComponent';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
	name: 'App',

	components: {
		AppSearch,
		Notification,
		PostUpdate,
		NavigationSidebar,
	},

	data () {
		return {
			isLoggedIn: false,

			newPosts: [],
		};
	},

	computed: {
		...mapState([
			'searchOpen',
			'menuOpen',
			'user',
			'tiers',
		]),
	},

	watch: {
		'$route' (to, from) {
			if (to.path !== from.path) {
				this.isLoggedIn = window.auth.isLoggedIn();
			}

			if(to.hash === '#menuOpen') {
				this.openMenu();
			} else {
				this.closeMenu();
			}

		},
	},

	mounted () {

		this.loadUser();
		this.loadTiers();
		this.isLoggedIn = window.auth.isLoggedIn();
		this.listenToOnlineStatus();
		this.touchActivity();

		this.$root.$on('openSearch', () => {
			this.openSearch();
		});

		this.$root.$on('openMenu', () => {
			// Get the current path
			let currentPath = this.$route.path;
			// Trim trailing slash
			currentPath = currentPath.replace(/\/$/, '');
			if(this.menuOpen) {
				this.$router.push({ path: currentPath }).catch(()=>{});
				this.closeMenu();
			} else{
				 this.openMenu();
				 this.$router.push({ path: currentPath + '#menuOpen' }).catch(()=>{});
			}
		});


		setInterval(() => {
			this.touchActivity()
		}, 60000);
	},

	methods: {
		...mapActions(['loadUser', 'loadTiers', 'touchActivity']),

		...mapMutations(['setOnline', 'openSearch', 'openMenu', 'closeMenu']),


		listenToOnlineStatus () {
			window.addEventListener('online', () => {
				this.setOnline(true);
			});

			window.addEventListener('offline', () => {
				this.setOnline(false);
			});
		},
	},
};
</script>
