<template>
	<article
		v-if="ready"
		class="'article container"
	>
		<section
			v-for="(layout, index) in post.acf.flexible_content"
			:key="index"
			:class="'layout layout--' + layout.acf_fc_layout + ' index--' + index"
		>
			<section-header
				v-if="layout.acf_fc_layout == 'section_header'"
				:layout="layout"
				:title="post.title"
				:index="index"
			/>

			<app-quick-links
				v-if="layout.acf_fc_layout == 'section_header'"
				:sub-title="post.title.rendered"
			/>

			<custom-menu
				v-if="layout.acf_fc_layout == 'menu'"
				:menu="layout.menu"
			/>

			<mixed-content
				v-if="layout.acf_fc_layout == 'mixed_content'"
				:layout="layout"
			/>

			<mixed-content-carousel
				v-if="layout.acf_fc_layout == 'mixed_content_carousel'"
				:layout="layout"
			/>

			<mixed-content-grid
				v-if="layout.acf_fc_layout == 'mixed_content_grid'"
				:layout="layout"
			/>

			<chat-to-mash-phone
				v-if="layout.acf_fc_layout == 'contact_phone'"
				:layout="layout"
			/>

			<chat-to-mash-mail
				v-if="layout.acf_fc_layout == 'contact_mail'"
				:layout="layout"
			/>

			<mixed-content-form
				v-if="layout.acf_fc_layout == 'mixed_content_form'"
				:form="layout.form"
			/>

			<full-width-image
				v-if="layout.acf_fc_layout == 'full_width_image'"
				:image="layout.image"
			/>

			<pirkx-dashboard
				v-if="layout.acf_fc_layout == 'pirkx_dashboard_link'"
				:layout="layout"
			/>
			
			<google-form v-if="layout.acf_fc_layout =='google_form_embed'" :layout="layout"></google-form>
		</section>
	</article>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';
import MixedContentCarousel from '@/components/ArticleComponents/MixedContentCarouselComponent';
import MixedContentGrid from '@/components/ArticleComponents/MixedContentGridComponent';
import MixedContentForm from '@/components/ArticleComponents/MixedContentFormComponent';
import CustomMenu from '@/components/ArticleComponents/CustomMenuComponent';
import ChatToMashPhone from '@/components/ChatToMashPhoneComponent';
import ChatToMashMail from '@/components/ChatToMashMailComponent';
import FullWidthImage from '@/components/ArticleComponents/FullWidthImageComponent';
import PirkxDashboard from '@/components/ArticleComponents/PirkxDashboardComponent';
import GoogleForm from '@/components/GoogleFormComponent';

export default {
	name: 'NewsArticle',

	components: {
		SectionHeader,
		MixedContent,
		MixedContentCarousel,
		MixedContentGrid,
		CustomMenu,
		ChatToMashPhone,
		ChatToMashMail,
		FullWidthImage,
		MixedContentForm,
		PirkxDashboard,
		GoogleForm
	},

	data () {
		return {
			post: null,
			ready: false,
		};
	},

	created () {
		this.get_post();
	},

	methods: {
		get_post () {
			let slug = this.$route.params.slug;
			let url = 'wp-json/wp/v2/posts/' + slug + '?_embed';
			if (isNaN(slug)) {
				url = 'wp-json/wp/v2/posts?_embed&slug=' + slug;
			}
			window.axios.get(url)
				.then(res => {
					if (res.status !== 200) {
						// Show some error or 404 page
						this.$router.replace({name: 'error'});
					}
					if (isNaN(slug)) {
						if (![...res.data].length) {
						// Show some error or 404 page
							this.$router.replace({name: 'oops'});
						}

						this.post = [...res.data][0];
					} else {
						this.post = {...res.data};
					}

					this.ready = typeof (this.post.acf) !== 'undefined';
					this.send_read_report();
				}).catch(err => {
					// Show some error or 404 page
					console.log(err);
					this.$router.replace({name: 'error'});
				});
		},

		send_read_report () {
			window.axios.post('wp-json/kxi/v1/read-report', {post_id: this.post.id}).then();
		},
	},
};
</script>
