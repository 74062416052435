<template>
	<div class="reset container">

		<app-header
			:show-logo="true"
			:show-notifications="false"/>

		<div class="reset__header">
			<h1>Reset Password</h1>
			<p v-show="!sent">Fill in the form below to reset your password</p>
		</div>

		<form
			action=""
			class="reset__form"
			@submit.prevent="submit">
			<input
				v-show="!sent"
				v-model="username"
				:disabled="loading"
				type="text"
				placeholder="Username"
				class="form__control"
				autocomplete="false">
			<p
				v-if="errors.username"
				class="error">{{ errors.username }}</p>

			<input
				v-show="!sent"
				v-model="password"
				:disabled="loading"
				type="password"
				placeholder="New Password"
				class="form__control"
				autocomplete="false">
			<p
				v-if="errors.password"
				class="error">{{ errors.password }}</p>

			<input
				v-show="!sent"
				v-model="confirmPassword"
				:disabled="loading"
				type="password"
				placeholder="Confirm New Password"
				class="form__control"
				autocomplete="false">
			<p
				v-if="errors.confirm_password"
				class="error">{{ errors.confirm_password }}</p>
			<p
				v-if="errors.key"
				class="error">{{ errors.key }}</p>

			<p
				v-if="error"
				class="error">{{ error }}</p>
			<button
				v-show="!sent"
				:disabled="sent || loading"
				class="button button--pill login__button bold ripple"
				type="submit">Reset Password</button>
			<p v-if="message">{{ message }}</p>
			<router-link :to="{name: 'login'}">Back to Login</router-link>
		</form>
	</div>
</template>

<script>
import AuthService from '@/services/AuthService';
export default {
	data () {
		return {
			key: '',
			username: '',
			password: '',
			confirmPassword: '',
			message: '',
			error: '',
			errors: {},
			sent: false,
			loading: false,
		};
	},
	mounted () {
		if (!this.$route.query.key || this.$route.query.key === '') {
			console.error('No key provided in URL for password reset');
			this.$router.replace({name: 'login'});
		}
		this.key = this.$route.query.key || '';
		this.username = this.$route.query.username || '';
	},
	methods: {
		submit () {
			this.message = 'Submitting, please wait...';
			this.error = '';
			this.errors = {};
			this.loading = true;
			AuthService.resetPassword(this.key, this.username, this.password, this.confirmPassword).then(res => {
				this.message = res.data.message || '';
				this.sent = true;
				this.loading = false;
			}).catch(err => {
				this.message = '';
				this.errors = err.response.data.errors || {};
				this.error = err.response.data.error || 'Something went wrong, please try again';
				this.password = '';
				this.confirmPassword = '';
				this.loading = false;
			});
		},
	},
};
</script>
