<template>
	<article class="article container">
		<section class="layout layout--section_header">
			<section-header
				:layout="{ theme: ['red-blue'] }"
				:title="{ rendered: 'Error' }"
			/>
		</section>

		<section class="layout layout--mixed_content">
			<mixed-content :layout="{ mixed_content: '<p>Error occured.</p>' }" />
		</section>
	</article>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';

export default {
	name: 'KXiError',

	components: {
		SectionHeader,
		MixedContent,
	},
};
</script>
