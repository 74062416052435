<template>
	<div class="chat-to-mash__item chat-to-mash__item--phone">
		<p>
			<a
				:href="'tel:' + phoneNumber"
				v-text="displayText"
			/>
		</p>
	</div>
</template>

<script>
export default {
	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			displayText: this.layout.display_text,
			phoneNumber: this.layout.phone_number,
		};
	},
};
</script>
