<template>
	<div class="training-profile container">


		<h2 class="section__title">Training Profile</h2>
		<transition name="fade">
		<div v-if="!loaded" class="loading-spinner">
			<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
				<g fill="none" fill-rule="evenodd">
					<g transform="translate(1 1)" stroke-width="2">
						<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
						<path d="M36 18c0-9.94-8.06-18-18-18">
							<animateTransform
								attributeName="transform"
								type="rotate"
								from="0 18 18"
								to="360 18 18"
								dur="1s"
								repeatCount="indefinite"/>
						</path>
					</g>
				</g>
			</svg>
		</div>
		</transition>
			<transition name="fade">
				<div v-if="loaded">
					
					<div v-if="profile.error">
						<header class="training-profile__details mt-10" >
							<p class="training-profile__details__name"> {{currentUser.name}}</p>
							<p class="training-profile__details__role">Your training profile has not been fully set up yet, please check back soon.</p>
						</header>
					</div>
					<div v-else>
						<header class="training-profile__details mt-10" >
							<p class="training-profile__details__name"> {{currentUser.name}}</p>
							<p class="training-profile__details__role">{{profile.profile.Role}}</p>
							<p class="training-profile__details__joined">{{profile.profile['Date Joined']}}</p>
						</header>

						<div class="training-profile__row">
							
							<profile-progress :radius="65" :progress="progress" :stroke="5" />
							<div v-for="(pro, idx) in profile" :key="idx" class="training-profile__row__item" :class="themes[idx]">
									<profile-card :keys="pro" :theme="themes[idx]" :title="idx" :list="true" :fullwidth="false" v-if="idx !== 'profile'"/>
							</div>
						</div>
						<profile-achievements v-if="profile.profile.achievements" :achievements="profile.profile.achievements"/>
					</div>
				</div>
			</transition>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ProfileProgress from './TrainingComponents/ProfileProgress.vue';
import ProfileCard from './TrainingComponents/ProfileCard';
import ProfileAchievements from './TrainingComponents/ProfileAchievements.vue';

export default {
	components: {
		ProfileCard,
		ProfileProgress,
		ProfileAchievements,
	},
	data() {
		return {
			'active' : null,
			themes: {
				'General Team Performance' : 'orange order-1 general-team',
				'Bonus Targets' : 'red bonus-targets',
				'iHASCO' : 'blue',
				'Profile Guidelines' : 'purple training-profile__card--fullwidth'
			},
			progress: 0,
		}
	},
	computed: {
		...mapState('trainingProfile', {
			profile: 'profile',
			loaded: 'loaded',
		}),
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
		
	},

	watch: {
		loaded (after) {
			if(after) {
				this.triggerCounter();
			}
		}
	},
	mounted () {
		this.loadProfile();

	},
	
	methods: {
		...mapActions('trainingProfile', [
			'loadProfile',
		]),

		componentName(name) {
			return 'Profile'+name.replace(/\s+/g, '');
		},

		triggerCounter() {
			if(this.profile.profile){
			const interval = setInterval(() => {
				this.progress += 1;
				if (this.progress === parseInt(this.profile.profile['Widget Total %']))
					clearInterval(interval);
				}, 5);
				}
		}
	},

};
</script>
