<template>
	<div
		:class="{
			'notification--info': notificationType === 'info',
			'notification--ready': notificationType
		}"
		class="notification"
	>
		<p class="notification__text">{{ notificationText }}</p>
		<a
			class="notification__action"
			@click="handleAction"
		>{{ notificationActionText }}</a>
	</div>
</template>

<script>
export default {
	data () {
		return {
			notificationType: false,
			notificationText: '',
			notificationActionText: '',
			notificationAction: function () {},
		};
	},

	mounted () {
		this.addEventListeners();
	},

	methods: {
		handleAction () {
			this.notificationAction();
		},

		swUpdate () {
			this.notificationType = 'info';
			this.notificationText = 'An update is available';
			this.notificationActionText = 'Refresh';
			this.notificationAction = function () {
				window.location.reload(true);
			};
		},

		addEventListeners () {
			window.addEventListener('sw:updated', () => {
				this.swUpdate();
			});
		},
	},

};
</script>
