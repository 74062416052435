<template>
<div class="container training-sessions">
		<div class="training-sessions__loading" v-if="loading">
			<svg width="100" height="100" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff" v-if="loading">
				<g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
						<path d="M36 18c0-9.94-8.06-18-18-18">
							<animateTransform attributeName="transform"	type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
						</path>
					</g>
				</g>
			</svg>
		</div>
		<form v-if="!loading && trainingSession" @submit.prevent="submit">
			<h2 class="training-sessions__title">{{trainingSession.title}}</h2>
			<TrainingSessionAttendeeFormComponent
				:form="mergedForm"
				:session="trainingSession"
				v-if="mergedForm && trainingSession"
			/>
		</form>
	</div>
</template>
<script>
import TrainingSessionsService from '@/services/TrainingSessionsService';
import TrainingSessionAttendeeFormComponent from '@/components/TrainingSessionsComponents/TrainingSessionAttendeeFormComponent';

export default {
	components: {
		TrainingSessionAttendeeFormComponent,
	},
	data() {
		return {
			loading: true,
			form: {},
			trainingSession: null,
			page: null
		}

	},
	mounted() {
		this.sessionId = this.$route.params.id;
		this.getTrainingPage();
		this.loadTrainingSession();
	},
	methods: {
		getTrainingPage() {
			
			let url = 'wp-json/wp/v2/pages?_embed&slug=training-questionnaire';
			window.axios.get(url)
				.then(res => {
					if (res.status !== 200) {
						// Show some error or 404 page
						this.$router.replace({name: 'error'});
					}
					this.page = res.data;
				}).catch(err => {
					// Show some error or 404 page
					console.log(err);
					this.$router.replace({name: 'error'});
				});
		},
		loadTrainingSession() {
			TrainingSessionsService.show(this.sessionId).then(res => {
				this.trainingSession = res.data;
				this.loading = false;
			});
		},

	},
	computed: {
		mergedForm() {
			if(!this.page) {
				return false;
			}

			if(!this.trainingSession) {
				return false;
			}

			let formData = this.page[0].acf.flexible_content.find(function(item){
				return item.acf_fc_layout === 'mixed_content_form'
			});

			if(!formData) {
				return false;
			}

			// Convert out training session questions in to match the content form attributes
			this.trainingSession.questions.forEach(function(q) {
				if(q.type === 'checkbox') {
					q.choices.map(function(c,ci) {
						c.label = c.value;
						c.id = 'ts-'+q.id+'.'+ci;
						return c;
					});
				}

				if(q.type === 'question' || q.type === 'radio') {
					q.choices.map(function(c) {
						c.label = c.value;
						c.text = c.value;
						return c;
					});
				}
				formData.form.fields.push({
					'choices': q.choices,
					'inputs': q.choices,
					'isRequired': true,
					'minLength': q.length,
					'type': q.type,
					'id': 'ts-'+q.id,
					'label': q.label
				})
			});

			this.loading = false; // eslint-disable-line vue/no-side-effects-in-computed-properties
			return formData.form;
		}
	}
};
</script>
<style scoped lang="scss">
</style>