<template>
	<div class="notifications container">
		<div class="notifications__header">
			<h1 class="notifications__title">Notifications</h1>
			<a href="#" class="notifications__header__clear" @click.prevent="clearAll" v-if="notifications.length > 0">Mark all as read</a>
		</div>
		<div class="notifications__body">
			<transition name="fade">
				<svg
					v-show="!loaded"
					width="100"
					height="100"
					viewBox="0 0 38 38"
					xmlns="http://www.w3.org/2000/svg"
					stroke="#fff"
					class="timeline__loader">
					<g
						fill="none"
						fill-rule="evenodd"><g
							transform="translate(1 1)"
							stroke-width="2"><circle
								stroke-opacity=".5"
								cx="18"
								cy="18"
								r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
									<animateTransform
										attributeName="transform"
										type="rotate"
										from="0 18 18"
										to="360 18 18"
										dur="1s"
										repeatCount="indefinite"/></path></g></g>
				</svg>
			</transition>
			<span
				v-if="!loaded"
				class="notifications__status" style="text-align:center">Loading notifications...</span>

				<div v-if="loaded && notifications.length < 1">
					<div class="timeline__empty">
						<h2 class="timeline__empty--title">It's very quiet</h2>
						<p class="timeline__empty--intro">You currently have no notifications.</p>
					</div>
				</div>


			<transition name="fade">
				<div>
				<div
					v-if="notifications.length > 0 && loaded"
					class="notifications__list">
					<notification
						v-for="n in notifications"
						:key="n.id"
						:notification="n"/>
				</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notification from './NotificationRowComponent';
export default {
	components: {
		Notification,
	},
	computed: {
		...mapState('notifications', {
			notifications: 'all',
			loaded: 'loaded',
		}),
	},
	mounted () {
		this.loadNotifications();
	},
	methods: {
		...mapActions('notifications', [
			'loadNotifications',
			'markAsRead',
		]),

		clearAll() {
			let ids = this.notifications.filter(notifcation => !notifcation.is_read).map(notifcation => notifcation.id);
			this.markAsRead(ids);
		}
	},
};
</script>
