<template>
	<div class="googleform ">
		<div v-html="googleForm"></div>
	</div>
</template>

<script>


export default {
	name: 'GoogleForm',

	components: {
	},

	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {};
	},

	computed: {
		googleForm() {
			let str = this.layout.google_form_url.replace(/edit|#responses/gi, '');
			return '<iframe src="'+str+'/viewform?embedded=true" style="margin: 2rem auto; max-width: 640px; width: 100%; display:block" height="'+ this.layout.form_height+'" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>';
		}
	}
};
</script>
