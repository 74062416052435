<template>
	<section class="timeline__comments">
		<div
			v-for="(comment, key) in mergedComments"
			:key="key+refresh"
			class="comment"
		>
			<Comment :comment="comment" :userId="user.id" :canModerate="isAdmin" @commentApproved="$emit('commentApproved')" @commentRejected="$emit('commentRejected')" @reply="triggerReply"/>
		</div>

		<a
			v-if="commentsToUnfold < totalMergedComments.length"
			class="timeline__comments__loadmore"
			@click.prevent="loadMoreComments"
		>Load more comments</a>

		<form
			:class="{ 'comment__compose--no-previous-comments': totalMergedComments.length === 0 }"
			class="comment__compose"
			@submit.prevent="submit"
		>
			<Mention
				ref="mention"
				:postId="postId"
				@update="setNewValue"/>

			<label
				:class="{'active' : hasImage}"
				for="image"
				class="icon-button"
				style="margin-right: .75rem;"
			>
				<input
					id="image"
					type="file"
					name="image"
					style="display:none"
					@change="filesChange($event.target.name, $event.target.files);"
				>
				<svg
					width="22"
					height="18"
					viewBox="0 0 22 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					style="position: relative; top: 2px;"
				>

					<path
						d="M1.25 12.75L6.409 7.591C6.61793 7.38206 6.86597 7.21633 7.13896 7.10325C7.41194 6.99018 7.70452 6.93198 8 6.93198C8.29548 6.93198 8.58806 6.99018 8.86104 7.10325C9.13403 7.21633 9.38207 7.38206 9.591 7.591L14.75 12.75M13.25 11.25L14.659 9.841C14.8679 9.63206 15.116 9.46633 15.389 9.35325C15.6619 9.24018 15.9545 9.18198 16.25 9.18198C16.5455 9.18198 16.8381 9.24018 17.111 9.35325C17.384 9.46633 17.6321 9.63206 17.841 9.841L20.75 12.75M2.75 16.5H19.25C19.6478 16.5 20.0294 16.342 20.3107 16.0607C20.592 15.7794 20.75 15.3978 20.75 15V3C20.75 2.60218 20.592 2.22064 20.3107 1.93934C20.0294 1.65804 19.6478 1.5 19.25 1.5H2.75C2.35218 1.5 1.97064 1.65804 1.68934 1.93934C1.40804 2.22064 1.25 2.60218 1.25 3V15C1.25 15.3978 1.40804 15.7794 1.68934 16.0607C1.97064 16.342 2.35218 16.5 2.75 16.5ZM13.25 5.25H13.258V5.258H13.25V5.25ZM13.625 5.25C13.625 5.34946 13.5855 5.44484 13.5152 5.51517C13.4448 5.58549 13.3495 5.625 13.25 5.625C13.1505 5.625 13.0552 5.58549 12.9848 5.51517C12.9145 5.44484 12.875 5.34946 12.875 5.25C12.875 5.15054 12.9145 5.05516 12.9848 4.98484C13.0552 4.91451 13.1505 4.875 13.25 4.875C13.3495 4.875 13.4448 4.91451 13.5152 4.98484C13.5855 5.05516 13.625 5.15054 13.625 5.25V5.25Z"
						stroke="currentcolor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"/>
				</svg>
			</label>
			<button class="button comment__compose__post" :disabled="posting">
				<span v-show="posted && !posting ">
					Posted!
				</span>
				<span v-show="posting && !posted">
					Posting...
				</span>
				<span v-show="!posting && !posted">
					Post
				</span>
				<svg
					v-show="posting"
					width="100"
					height="100"
					viewBox="0 0 38 38"
					xmlns="http://www.w3.org/2000/svg"
					stroke="#fff"
					class="loading-spinner w-6 h-6">
					<g
						fill="none"
						fill-rule="evenodd"><g
							transform="translate(1 1)"
							stroke-width="2"><circle
								stroke-opacity=".5"
								cx="18"
								cy="18"
								r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
									<animateTransform
										attributeName="transform"
										type="rotate"
										from="0 18 18"
										to="360 18 18"
										dur="1s"
										repeatCount="indefinite"/></path></g></g></svg>
			</button>
		</form>

		<span v-if="posted" class="comment__replyto comment__replyto--success">
			Comment has been posted
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 14px; height: 14px;">
			<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>

		</span>

		<span v-if="form.comment_parent" class="comment__replyto" @click="form.comment_parent = null">
			replying to {{this.replyCommentAuthor}} 
			<svg width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8125 10.8125L17.1875 17.1875M17.1875 10.8125L10.8125 17.1875M26.75 14C26.75 15.6744 26.4202 17.3323 25.7795 18.8792C25.1387 20.4261 24.1996 21.8317 23.0156 23.0156C21.8317 24.1996 20.4261 25.1387 18.8792 25.7795C17.3323 26.4202 15.6744 26.75 14 26.75C12.3256 26.75 10.6677 26.4202 9.12079 25.7795C7.57388 25.1387 6.16834 24.1996 4.98439 23.0156C3.80044 21.8317 2.86128 20.4261 2.22054 18.8792C1.57979 17.3323 1.25 15.6744 1.25 14C1.25 10.6185 2.5933 7.37548 4.98439 4.98439C7.37548 2.5933 10.6185 1.25 14 1.25C17.3815 1.25 20.6245 2.5933 23.0156 4.98439C25.4067 7.37548 26.75 10.6185 26.75 14Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
		</span>

		<div
						v-if="hasImage"
						class="post-update__attachments"
					>
						<div class="post-update__attachments__image__wrap"  v-for="(img, ikey) in images"  :key="'image-'+ ikey">
							<img :src="genImagePreview(img)" class="post-update__attachments__image"/>
							<button @click.prevent="removeImage(ikey)"  type="button" class="post-update__attachments__image__remove">
									<svg
										width="20"
										height="20"
										viewBox="0 0 28 28"
										fill="white"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.8125 10.8125L17.1875 17.1875M17.1875 10.8125L10.8125 17.1875M26.75 14C26.75 15.6744 26.4202 17.3323 25.7795 18.8792C25.1387 20.4261 24.1996 21.8317 23.0156 23.0156C21.8317 24.1996 20.4261 25.1387 18.8792 25.7795C17.3323 26.4202 15.6744 26.75 14 26.75C12.3256 26.75 10.6677 26.4202 9.12079 25.7795C7.57388 25.1387 6.16834 24.1996 4.98439 23.0156C3.80044 21.8317 2.86128 20.4261 2.22054 18.8792C1.57979 17.3323 1.25 15.6744 1.25 14C1.25 10.6185 2.5933 7.37548 4.98439 4.98439C7.37548 2.5933 10.6185 1.25 14 1.25C17.3815 1.25 20.6245 2.5933 23.0156 4.98439C25.4067 7.37548 26.75 10.6185 26.75 14Z"
											stroke="currentColor"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
							</button>
						</div>
</div>
	</section>

</template>
<script>
import Comment from '@/components/TimelineComponents/CommentComponent';
import Mention from '@/components/MentionComponent';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
	components: {
    	Mention,
		Comment,
	},

	props: {
		comments: {
			type: Array,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
		postId: {
			type: Number,
			required: true,
		},
	},

	data () {
		return {
			formData: new FormData(),
			form: {
				content: null,
				user: this.user.id,
				user_name: this.user.name,
				post: this.postId,
				comment_parent: null,
			},
			refresh:0,
			comment: '',
			newComments: [],
			hasImage: false,
			replyCommentAuthor: null,
			text: '',
			posting : false,
			posted: false,
			images: [],
			commentsToUnfold: 5,
		};
	},

	computed: {
		isAdmin () {
			if (this.user.role === 'editor' || this.user.role === 'administrator') {
				return true;
			}

			return false;
		},

		mergedComments () {
			return this.newComments.concat(this.comments).splice(0, this.commentsToUnfold);
		},

		totalMergedComments () {
			return this.newComments.concat(this.comments);
		},
	},

	mounted () {
	},

	methods: {
		loadMoreComments () {
			this.commentsToUnfold = this.commentsToUnfold + 5;
		},

		setNewValue(content) {
			this.form.content = content;
		},

		triggerReply(comment) {
			this.replyCommentAuthor = comment.comment_author;
			this.form.comment_parent = comment.comment_ID;
			this.$refs.mention.$el.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
			 document.getElementById('comment-input-'+this.postId).focus({preventScroll: true});
		},

		filesChange (fieldName, fileList) {
			if (!fileList.length) {
				this.hasImage = false;
				return;
			}

			this.hasImage = true;
			this.image = fileList[0];

			this.images = [];

			Array
				.from(Array(fileList.length).keys())
				.map(x => {

					if (fieldName === 'image') {
						this.images.push(fileList[x]);
					}

					this.formData.append(fieldName+'[' + x + ']', fileList[x], fileList[x].name);
				});
		},

		genImagePreview(file) {
			if (file) {
				return file ? URL.createObjectURL(file) : null;
			}

			return null;
		},

		removeImage(idx) {
			this.images.splice(idx, 1);
			this.formData.delete('image['+idx+']');
			if(this.images.length < 1) {
				this.hasImage = false
			}
		},

		addCommentToNest(newComment, tempKey) {
			if(this.form.comment_parent) {
				// Top level comments always array
				let index = this.mergedComments.findIndex((comment) => {
					// Try find in comments on top level
					return comment.comment_ID === this.form.comment_parent;
				});

				if( index > -1 ) {
					// We have an index for the parent,
					// lets add to nested
					if(typeof this.mergedComments[index].nested === 'undefined') {
						this.mergedComments[index].nested = {}
					}
					this.mergedComments[index].nested[newComment.comment_ID] = newComment;
				}
				

				if(index === false || index < 0) {
					// Loop through our comments and look for a nested
					for (let i = 0; i < this.mergedComments.length; i++) {
						let topLevelComment = this.mergedComments[i];
						if(topLevelComment.nested) {
							if(topLevelComment.nested[this.form.comment_parent]) {
								if(typeof topLevelComment.nested[this.form.comment_parent].nested === 'undefined') {
									topLevelComment.nested[this.form.comment_parent].nested = {}
								}

								topLevelComment.nested[this.form.comment_parent].nested[newComment.comment_ID] = newComment;
							} 
						}
					}
				}
				this.refresh++;

			} else {
				if(tempKey) {
					return;
				}
				this.mergedComments.unshift(newComment);
			}
		},

		submit () {
			this.posting = true;
			if(!this.form.content && this.images.length < 1) {
				this.posting = false;
				return 
			}

			if (this.images.length < 1 && this.form.content !== null && this.form.content.trim() === '') {
				this.posting = false;
				return;
			}

			this.formData.append('content', this.form.content);
			this.formData.append('user', this.form.user);
			this.formData.append('user_name', this.form.user_name);
			this.formData.append('post', this.form.post);
			this.formData.append('comment_parent', this.form.comment_parent);

			let tempKey = Date.now()
			let newComment = {
				image: this.images.length > 0 ? this.genImagePreview(this.images[0]) : null,
				comment_author: this.form.user_name,
				human_date: 'Just posted',
				new: true,
				comment_content: this.form.content,
				comment_ID: tempKey,
				tempKey: tempKey,
				comment_parent: this.form.comment_parent,
				user_id: this.user.id,
				comment_approved: (this.user.auto_approve_comments) ? 1 : 0,
				nested: {},
				author_rank:  this.user.rank,
				avatar: this.user.profile_avatar,
			};
			
			this.addCommentToNest(newComment);

			this.form.content = '';
			this.form.comment_parent = null;
			this.hasImage = false;

			setTimeout(() => {
				this.posting = false;
				this.posted = true;
			}, 200);

			this.$refs.mention.reset();
			window.axios.post('wp-json/kxi/v1/timeline/comment/', this.formData).then(res => {
				newComment.comment_ID = res.data;
				this.addCommentToNest(newComment, tempKey);
				this.$emit('commentsUpdated', newComment, this.mergedComments);
				gtag('event', 'action', {'event_category': 'comment', 'event_label': 'Posted comment ID:' + this.comment.comment_ID+ '; User:' + this.user.id});
			});

			this.formData = new FormData();

			setTimeout(() => {
				this.posted = false
			}, 2000);
		},
	},
};
</script>

<style>

</style>
