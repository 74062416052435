<template>
	<div>
		<div class="comment__wrap" v-if="visible">
			<div class="comment__inside">
				<div class="comment__inside__hold">
					<header class="comment__header">
						<router-link :to="{ name: 'usersprofile', params: { id: comment.user_id }}" class="comment__author__link">
							<user-badge :rank="comment.author_rank.tier_name" :avatar="comment.avatar" :color="comment.author_rank.tier_colour"></user-badge>
							<p class="comment_author">{{ comment.comment_author }}</p>
							
						</router-link>
						<span class="comment__likes" v-show="likeCount > 0" :class="{'comment__likes--liked' : hasLiked}" @click.prevent="showLikesPopup"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"></path></svg> {{likeCount}}</span>
						<span class="timeline__post__comment-moderation" v-if="comment.comment_approved == 0 && (comment.user_id == userId || canModerate) && !isModerated">( In Review )</span>
						<div class="comment__header__right">
							<time class="timeline__post__time timeline__post__time--comment">{{ comment.new ? 'Just now' : humanDate }}</time>
							<div
								v-if="canModerate || comment.user_id == userId"
								class="timeline__post__menu timeline__post__menu--comment"
								@click="showingMenu = !showingMenu">
								<svg
									id="Capa_1"
									fill="currentColor"
									height="25"
									width="25"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink"
									viewBox="0 0 32.055 32.055"
									xml:space="preserve">
									<path
										d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
										C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
										s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
										c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/>
								</svg>
								<transition name="slide">
									<div
										v-if="showingMenu"
										class="timeline__post__menu__dropdown">
										<ul>
											<li
												v-if="!isModerated && canModerate && comment.comment_approved == 0"
												class="timeline__post__menu__dropdown__action"
												@click="approveComment"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
													<path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
												</svg>
													Approve Comment</li>
											<li
												class="timeline__post__menu__dropdown__action"
												@click="rejectComment">
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
													<path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
												</svg>

												Delete Comment</li>
										</ul>
									</div>
								</transition>
							</div>
						</div>

					</header>

					<div
						:class="{ 'clamped': !readingMore }"
						class="comment__body"
						@click.prevent="readingMore = true"
						v-html="content"
					/>

					<div v-if="comment.image"  class="comment__image__wrap">
						<img :src='comment.image' class="comment__image"/>
					</div>

					<div class="comment__footer">
						<button class="comment__footer__reply" @click="reply">Reply</button>
						<button class="comment__footer__like" @click="like"><span v-if="hasLiked">Unlike</span> <span v-else>Like</span></button>
					</div>
				</div>
			</div>
		</div>
		<div class="comment_children" v-if="comment.nested">
			<div
				v-for="(child, childkey) in comment.nested"
				:key="childkey"
				class="comment comment--child"
			>
				<Comment :comment="child" :userId="userId" :canModerate="canModerate" @commentApproved="$emit('commentApproved')" @commentRejected="$emit('commentRejected')" @reply="$emit('reply', child)"/>
			</div>
		</div>

		<Transition name="fade">
			<div class="timeline__likedby__list" v-if="showLikes">
				<div  v-for="(liker,id) in comment.liked_by" :key="'liked-by-'+id">
					<a :href="'/profile/'+liker.ID" class="timeline__likedby__list__user" v-if="liker.ID != currentUser.id || hasLiked">
						<user-badge :rank="liker.rank.tier_name" :colour="liker.rank.tier_colour" :avatar="liker.avatar"></user-badge> {{liker.display_name}}  <span style="margin-left: 5px" v-if="currentUser.id == liker.ID">(You)</span>
					</a>
				</div>
					
					<button class="button timeline__likedby__list__bottom-back" @click="closeLikes">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
						</svg>
						Back to Timeline
					</button> 
			</div>
		</Transition>
	</div>
</template>
<script>
import UserBadge from '@/components/UserBadgeComponent';
import Comment from './CommentsComponent.vue';
import { mapState } from 'vuex';

export default {
	name: 'Comment',
	components: {
		Comment,
		UserBadge,
	},

	props: {
		comment: {
			required: true,
			type: Object,
		},
		userId: {
			required: true
		},
		canModerate: {
			default: false
		}
	},

	data () {
		return {
			showingMenu: false,
			readingMore: false,
			visible: true,
			showLikes: false,
			isModerated: false,
			hasLiked: this.comment.has_liked,
			likeCount : this.comment.like_count,
			userPushed : false,
		};
	},

	computed: {
		...mapState({
			currentUser: 'user',
		}),

		humanDate() {
			return window.moment(this.comment.human_date).fromNow();
		},
		content () {
			var regex = /(?=@)(.*?)(?= )/;
			let content = this.comment.comment_content;
			if(!content || content == "null")  {
				return '';
			}
			let string = this.comment.comment_content.match(regex);

			if (!string) {
				return this.comment.comment_content;
			}

			if (string.length < 1) {
				return this.comment.comment_content;
			}

			string.forEach(function (e) {
				content = content.replace(e, '<span class="tagged">' + e + '</span>');
			});

			return content;
		},
	},

	mounted () {
		if (this.content.length < 150) {
			this.readingMore = true;
		}
	},

	methods: {
		readMore () {
			this.readingMore = true;
		},

		reply () {
			this.$emit('reply', this.comment);
		},

		showLikesPopup() {
			if(this.likeCount > 0) {
				this.showLikes = true;
			}
		},

		like() {

			if(this.hasLiked) {
				this.hasLiked = false;
				this.likeCount = this.comment.has_liked ? this.comment.like_count - 1 : this.comment.like_count;
			} else {
				this.hasLiked = true;
				this.likeCount = !this.comment.has_liked ? this.comment.like_count + 1 : this.comment.like_count;

				// Push current Users to like list
				if(!this.comment.has_liked && !this.userPushed) {
					this.comment.liked_by.push({
						avatar: this.currentUser.profile_avatar,
						rank: this.currentUser.rank,
						display_name: this.currentUser.name,
					});
					this.userPushed = true;
				}

			}
			
			window.axios.put('wp-json/kxi/v1/timeline/comment/like/' + this.comment.comment_ID);

		},

		closeLikes() {
			this.showLikes = false;
		},

		approveComment () {
			this.isModerated = true;
			this.$emit('commentApproved');
			gtag('event', 'action', {'event_category': 'comment', 'event_label': 'approved' + this.comment.comment_ID});
			window.axios.put('wp-json/kxi/v1/timeline/comment/approve/'+this.comment.comment_ID).then(res => {});
		},
		rejectComment () {
			this.isModerated = true;
			this.visible = false;
			this.$emit('commentRejected');
			gtag('event', 'action', {'event_category': 'comment', 'event_label': 'rejected' + this.comment.comment_ID});
			window.axios.put('wp-json/kxi/v1/timeline/comment/reject/'+this.comment.comment_ID).then(res => {});
		}
	},
};
</script>
