<template>
	<div
		:data-theme="get_theme"
		:class="{
			'section-header--theme': get_style === 'theme',
			'section-header--image': get_style === 'image'
		}"
		class="section-header"
	>

		<div
			:style="get_background_property"
			class="section-header__background">
			<div class="section-header__black">
				<h1
					v-if="layout.hide_title === false"
					v-html="get_title"
				/>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	props: {
		layout: {
			type: Object,
			required: true,
		},
		title: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: false,
			default: 0,
		},
		slug: {
			type: String,
			default: '',
			required: false,
		},
	},

	computed: {
		get_content () {
			return this.layout.content;
		},

		get_title () {
			return this.title.rendered;
		},

		get_theme () {
			return this.layout.theme || 'red-blue';
		},

		get_style () {
			return this.layout.style;
		},

		get_background_property () {
			return this.get_style === 'image'
				? 'background-image: url(' + this.layout.image.sizes.medium_large + ')'
				: null;
		},

		get_thumbnail_image () {
			return this.layout.image.sizes.thumbnail;
		},

		get_medium_image () {
			return this.layout.image.sizes.medium_large;
		},

		get_large_image () {
			return this.layout.image.sizes.large;
		},

		get_full_image () {
			return this.layout.image.url;
		},

		get_post_title () {
			return this.layout.content;
		},
	},

	mounted () {
		if (this.index === 0) {
			const sectionHeader = document.querySelector('.section-header');
			const quickLinkBar = document.querySelector('.ql-bar');

			if (!quickLinkBar || !sectionHeader) {
				return;
			}

			if (this.slug !== 'feedback') {
				const headerBackground = document.querySelector('.section-header__background');
				const subTitle = document.querySelector('.ql-bar__sub-title');
				const offsetHeight = document.querySelector('.app-header').clientHeight + document.querySelector('.section-header').clientHeight - quickLinkBar.clientHeight;

				window.addEventListener('scroll', function () {
					let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

					if (scrollTop >= offsetHeight && !quickLinkBar.classList.contains('stuck')) {
						quickLinkBar.classList.add('stuck');
					} else if (scrollTop < offsetHeight && quickLinkBar.classList.contains('stuck')) {
						quickLinkBar.classList.remove('stuck');
					}

					if (scrollTop > offsetHeight) {
						return;
					}

					headerBackground.style.opacity = (1 - (scrollTop / 200));
					if (subTitle) {
						subTitle.style.opacity = ((1 - (200 - scrollTop) / 100) * 1);
					}

					sectionHeader.style.top = '-' + (scrollTop * 0.25) + 'px';
				}, {
					capture: true,
					passive: true,
				});
			}
		}
	},
};
</script>
