export default {
	index () {
		return window.axios.get('wp-json/notifications/index?posts_per_page=2&per_page=5&limit=5');
	},

	markAsRead (notificationIds) {
		return window.axios.post('wp-json/notifications/read', {
			ids: notificationIds,
		});
	},
};
