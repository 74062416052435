<template>
	<div class="mixed-content-carousel glide">
		<div
			class="glide__track"
			data-glide-el="track"
		>
			<div class="glide__slides">
				<mixed-content
					v-for="(slide, index) in get_slides"
					:key="index"
					:index="index"
					:layout="slide"
					class="mixed-content-carousel__slide"
				/>
			</div>
		</div>

		<div
			class="glide__bullets"
			data-glide-el="controls[nav]"
		>
			<button
				v-for="(slide, index) in get_slides"
				:key="index"
				:data-glide-dir="'=' + index"
				class="glide__bullet"
			/>
		</div>
	</div>
</template>

<script>
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';
import Glide from '@glidejs/glide';

export default {
	components: {
		MixedContent,
	},

	props: {
		layout: {
			type: Object,
			required: true,
		},
	},

	computed: {
		get_slides () {
			return this.layout.slides;
		},
	},

	mounted () {
		new Glide('.glide', {
			type: 'carousel',
			autoplay: 10000,
		}).mount();
	},
};
</script>
