import CookieService from './CookiesService';

export default {
	login (username, password) {
		const data = {
			username,
			password,
		};

		return new Promise((resolve, reject) => {
			window.axios.post('wp-json/jwt-auth/v1/token', data).then(res => {
				// If we're in the native app store the user
				if (typeof Android !== 'undefined') {
					// eslint-disable-next-line no-undef
					Android.storeUser(JSON.stringify(res.data));
				}
				this.setToken(res.data.token, res.data.expire_in, true);
				resolve(res);
			}).catch(err => {
				this.clearToken();
				reject(err);
			});
		});
	},

	setToken (token, expire, remember = false) {
		window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		window.$.ajaxSetup({
			beforeSend: function (xhr) {
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
		});

		if(remember) {
			CookieService.set('jwt-token', token, expire);
		}
	},

	clearToken () {
		console.log('cleartoken');
		delete window.axios.defaults.headers.common['Authorization'];
		CookieService.remove('jwt-token');
	},

	logout () {
		this.clearToken();
		if (window.location.pathname === '/login' || window.location.pathname === '/account-confirm' || window.location.pathname === '/reset-password' || window.location.pathname === '/reset-password/') {
			return;
		}
		window.location.replace('/login?redirect=' + window.location.href);
	},

	init () {
		const token = CookieService.get('jwt-token');
		if (token) {
			this.setToken(token);
			return;
		}

		this.clearToken();
	},

	isLoggedIn () {
		return CookieService.exists('jwt-token');
	},

	accountConfirm (data) {
		return window.axios.post('wp-json/kxi/v1/account-confirm', data);
	},

	forgotPassword (username) {
		return window.axios.post('wp-json/kxi/v1/forgot-password', {username});
	},

	resetPassword (key, username, password, confirmPassword) {
		return window.axios.post('wp-json/kxi/v1/reset-password', {key, username, password, 'confirm_password': confirmPassword});
	},
};
