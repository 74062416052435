<template>
	<div class="container">
		<section-header
			:layout="{theme: 'chat-to-mash-scheme', style: 'theme', hide_title: false,}"
			:title="{ rendered: 'Complete your Account' }"
		/>

		<form
			action="#"
			class="feedback-form form"
			@submit.prevent="submit"
		>

			<div class="mixed-content">
				<p class="">
					Enter your new MashKX password to complete your registration.
				</p>
			</div>

			<div>
				<label
					for="password"
					class="form__label"
				>New Password
				</label>
				<input
					id="password"
					v-model="fields.password"
					type="password"
					name="password"
					class="form__control"
				>
				<error-component
					v-if="errors.password.required"
					:text="errors.password.required"
				/>
				<error-component
					v-else-if="errors.password.minLength"
					:text="errors.password.minLength"
				/>
			</div>

			<div>
				<label
					for="password_confirmation"
					class="form__label"
				>Confirm Password
				</label>
				<input
					id="password_confirmation"
					v-model="fields.password_confirmation"
					type="password"
					name="password_confirmation"
					class="form__control"
				>
				<error-component
					v-if="errors.password_confirmation.sameAsPassword"
					:text="errors.password_confirmation.sameAsPassword"
				/>
			</div>

			<br>

			<button
				type="submit"
				class="button button--pill"
			>Submit</button>

			<div class="form__error-wrap">
				<error-component
					v-if="errors.token.required"
					:text="errors.token.required"
				/>
				<error-component
					v-if="errors.form"
					:text="errors.form"
				/>
			</div>

		</form>

	</div>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import ErrorComponent from '@/components/ErrorComponent';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
	components: {
		SectionHeader,
		ErrorComponent,
	},

	data () {
		return {
			fields: {
				password: '',
				password_confirmation: '',
				token: '',
			},
			errors: {
				form: '',
				password: {
					required: '',
					minLength: '',
				},
				password_confirmation: {
					sameAsPassword: '',
				},
				token: {
					required: '',
				},
			},
		};
	},

	validations: {
		fields: {
			password: {
				required,
				minLength: minLength(8),
			},
			password_confirmation: {
				sameAsPassword: sameAs('password'),
			},
			token: {
				required,
			},
		},
	},

	mounted () {
		this.fields.token = this.$route.query.token;

		this.errors.token.required = '';

		if (!this.fields.token) {
			this.errors.token.required = 'Token is required';
		}
	},

	methods: {
		submit () {
			this.errors.password.required = '';
			this.errors.password_confirmation.sameAsPassword = '';
			this.errors.password.minLength = '';

			if (this.$v.$invalid) {
				if (!this.$v.fields.password.required) {
					this.errors.password.required = 'Password is required';
				} else if (!this.$v.fields.password.minLength) {
					this.errors.password.minLength = 'Minimum 8 characters';
				}

				if (!this.$v.fields.password_confirmation.sameAsPassword) {
					this.errors.password_confirmation.sameAsPassword =
						'Passwords should match';
				}

				return;
			}

			let self = this;

			window.auth
				.accountConfirm(this.fields)
				.then(() => {
					self.$router.push('/login');
				})
				.catch(error => {
					this.errors.form = error.response.data.errors.message[0];
				});
		},
	},
};
</script>
